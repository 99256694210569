import React, { useEffect, useRef } from 'react';
import { observer, inject } from "mobx-react"

import DraggableListItem from './DraggableListItem';
import {
  DragDropContext,
  Droppable
} from 'react-beautiful-dnd';
import { toJS } from 'mobx';
 
const DraggableList = inject("store")(observer((props) => {

  const items = useRef(props.items)

  useEffect(() => {
    items.current = props.store.mapAuthorizedOverlayServiceList
    // eslint-disable-next-line
  }, [JSON.stringify(props.store.mapAuthorizedOverlayServiceList)]);

  const refreshLayerList = () => {
    //console.log("refresh layer list: ", toJS(props.store.mapAuthorizedOverlayServiceList)) 
    items.current = toJS(props.store.mapAuthorizedOverlayServiceList)
  }

  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <Droppable droppableId="droppable-list" style={{ padding: "0" }}>
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.current.map((item, index) => (
              <DraggableListItem item={item} index={index} key={item.id} refreshLayers={refreshLayerList} mapRef={props.mapRef}/>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}))

export default DraggableList;
