import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function Variants() {
  return (
    <div style={{width: "100%"}}>
      <Skeleton variant="rectangular" width={210} height={118} />
      <Skeleton variant="text" width={250} />
      <Skeleton variant="text" width={250}/>
      <Skeleton variant="text" width={200}/>
    </div>
  );
}