import { types } from "mobx-state-tree"

export const Risk = types
    .model({
        latitude: types.optional(types.number, 0),
        longitude: types.optional(types.number, 0),
        floodrisk: types.optional(types.number, 0),
        landsliderisk: types.optional(types.number, 0),
        earthquakerisk: types.optional(types.number, 0),
        floodrisk25m: types.optional(types.number, 0),
        landsliderisk25m: types.optional(types.number, 0),
        floodrisk50m: types.optional(types.number, 0),
        landsliderisk50m: types.optional(types.number, 0)
    }).actions(self => ({

    }))