import React, { useEffect } from 'react'
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Variants from '../../components/common/Variants'

import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Button, Typography } from '@mui/material';

import Draggable from 'react-draggable';

export const RiskResultMenu = inject("store")(observer((props) => {

    const navigate = useNavigate();

    /**
   * Feature seçildiğinde seçilen feature için layer bilgisinin alınmasını sağlar
   */
    useEffect(() => {
        //console.log("props.store.mapSelectedFeature: ", props.store.mapSelectedFeature)


        // eslint-disable-next-line
    }, [props.store.mapSelectedFeature]);

    /**
     * Öznitelik bilgileri menüsünü kapatır
     */
    function onAttributeTableClose() {
        props.store.setAttributeTableDisplay(false)
    }

    const onClickGotoRaporPage = () => {
        navigate("/rapor")
    }


    //console.log("selectedLayerFields: ", selectedLayerFields)

    return (
        <Draggable>
            <Card className="mps-attribute-table-container" hidden={(props.store.mapAttributeTableDisplay) ? false : true} >
                <CardHeader subheader={
                    <Typography variant="body2" style={{ fontWeight: "bold", marginBottom: "15px" }}>
                        SORGULANAN KONUMA AİT RİSK BİLGİLERİ
                    </Typography>
                } action={
                    <IconButton aria-label="settings" onClick={onAttributeTableClose}>
                        <CloseIcon />
                    </IconButton>
                }></CardHeader>
                <CardContent style={{ padding: "20px" }}>
                    {
                        (props.store.loadingBarStatus) ?

                            <Grid container spacing={0}>

                                <Grid item xs={3}>
                                    <img src='/assets/images/riskmap.png' alt="riskmap" style={{ width: "70px" }} />
                                </Grid>

                                <Grid item xs={9}>
                                    <Typography variant="body1"><b>Enlem:</b> {Number(props.store.selectedRisk[0]?.latitude).toFixed(5)} <b>(Derece)</b></Typography>
                                    <Typography variant="body1"><b>Boylam:</b> {Number(props.store.selectedRisk[0]?.longitude).toFixed(5)} <b>(Derece)</b></Typography>
                                </Grid>

                                <Grid item xs={12} style={{ marginBottom: "20px" }}>

                                </Grid>

                                <Grid item xs={4} style={{ textAlign: "center" }}>
                                    <Avatar sx={{
                                        width: 75, height: 75,
                                        bgcolor: (props.store.selectedRisk[0]?.landsliderisk === 5) ? "#ff0100" :
                                            (props.store.selectedRisk[0]?.landsliderisk === 4) ? "#ff5400" :
                                                (props.store.selectedRisk[0]?.landsliderisk === 3) ? "#ff7f01" :
                                                    (props.store.selectedRisk[0]?.landsliderisk === 2) ? "#ffea00" :
                                                        (props.store.selectedRisk[0]?.landsliderisk === 1) ? "#00e03c" : "",
                                        border: (props.store.selectedRisk[0]?.landsliderisk === 5) ? "3px solid #CB0909" :
                                            (props.store.selectedRisk[0]?.landsliderisk === 4) ? "3px solid #CB4902" :
                                                (props.store.selectedRisk[0]?.landsliderisk === 3) ? "3px solid #ff7f01" :
                                                    (props.store.selectedRisk[0]?.landsliderisk === 2) ? "3px solid #b4a605" :
                                                        (props.store.selectedRisk[0]?.landsliderisk === 1) ? "3px solid  #039229" : ""
                                    }} style={{ marginLeft: "15px", color: "#000" }}>
                                        {props.store.selectedRisk[0]?.landsliderisk}
                                    </Avatar>
                                    <Typography variant="body2" style={{ fontWeight: "bold", color: "#000" }}>Heyelan</Typography>
                                </Grid>

                                <Grid item xs={4} style={{ textAlign: "center" }}>
                                    <Avatar sx={{
                                        width: 75, height: 75,
                                        bgcolor: (props.store.selectedRisk[0]?.floodrisk === 5) ? "#ff0100" :
                                            (props.store.selectedRisk[0]?.floodrisk === 4) ? "#ff5400" :
                                                (props.store.selectedRisk[0]?.floodrisk === 3) ? "#ff7f01" :
                                                    (props.store.selectedRisk[0]?.floodrisk === 2) ? "#ffea00" :
                                                        (props.store.selectedRisk[0]?.floodrisk === 1) ? "#00e03c" : "",
                                        border: (props.store.selectedRisk[0]?.floodrisk === 5) ? "3px solid #CB0909" :
                                            (props.store.selectedRisk[0]?.floodrisk === 4) ? "3px solid #CB4902" :
                                                (props.store.selectedRisk[0]?.floodrisk === 3) ? "3px solid #cf6701" :
                                                    (props.store.selectedRisk[0]?.floodrisk === 2) ? "3px solid #b4a605" :
                                                        (props.store.selectedRisk[0]?.floodrisk === 1) ? "3px solid  #039229" : ""
                                    }} style={{ marginLeft: "15px", color: "#000" }}>
                                        {props.store.selectedRisk[0]?.floodrisk}
                                    </Avatar>
                                    <Typography variant="body2" style={{ fontWeight: "bold", color: "#000" }}>Taşkın</Typography>
                                </Grid>

                                <Grid item xs={4} style={{ textAlign: "center" }}>
                                    <Avatar sx={{
                                        width: 75, height: 75,
                                        bgcolor: (props.store.selectedRisk[0]?.earthquakerisk === 5) ? "#ff0100" :
                                            (props.store.selectedRisk[0]?.earthquakerisk === 4) ? "#ff5400" :
                                                (props.store.selectedRisk[0]?.earthquakerisk === 3) ? "#ff7f01" :
                                                    (props.store.selectedRisk[0]?.earthquakerisk === 2) ? "#ffea00" :
                                                        (props.store.selectedRisk[0]?.earthquakerisk === 1) ? "#00e03c" : "",
                                        border: (props.store.selectedRisk[0]?.earthquakerisk === 5) ? "3px solid #CB0909" :
                                            (props.store.selectedRisk[0]?.earthquakerisk === 4) ? "3px solid #CB4902" :
                                                (props.store.selectedRisk[0]?.earthquakerisk === 3) ? "3px solid #cf6701" :
                                                    (props.store.selectedRisk[0]?.earthquakerisk === 2) ? "3px solid #b4a605" :
                                                        (props.store.selectedRisk[0]?.earthquakerisk === 1) ? "3px solid  #039229" : ""
                                    }} style={{ marginLeft: "15px", color: "#000" }}>
                                        {props.store.selectedRisk[0]?.earthquakerisk}
                                    </Avatar>
                                    <Typography variant="body2" style={{ fontWeight: "bold", color: "#000" }}>Deprem</Typography>
                                </Grid>
                            </Grid> :
                            <Variants />
                    }
                </CardContent>
                <CardActions >
                    <Button variant="contained" color="primary" style={{ marginLeft: "auto" }} onClick={onClickGotoRaporPage}>
                        RİSK RAPORU
                    </Button>
                </CardActions>
            </Card>
        </Draggable>
    )
}))