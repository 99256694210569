import React, { useState } from 'react';
import { toJS } from 'mobx';
import { observer, inject } from "mobx-react"

import { Draggable } from 'react-beautiful-dnd';
import L from 'leaflet';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip'
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { OverlayService } from '../../../apis/model/arcgisserver/OverlayService'

const DraggableListItem = inject("store")(observer((props) => {

    const [activeSliderLayerItemIndex, setActiveSliderLayerItemIndex] = useState(-1)

    const onClickGetLayerLegendInfo = async (item, idx) => {
        props.store.setLoadingBarStatus(false)
        //console.log("item: ", item)
        if (activeSliderLayerItemIndex === idx) {
            setActiveSliderLayerItemIndex(-1)
        } else {
            setActiveSliderLayerItemIndex(idx)
        } 
        props.store.setLoadingBarStatus(true)

        props.refreshLayers()
    }

    /**
     * Harita tanımlı overlayLayerList katmanlarının açılıp kapatılmasını sağlar
     * @param {*} overlayLayer 
     */
    const toggleOverlayLayer = (inputOverlayLayer) => {

        if (props.mapRef.current.hasLayer(window[inputOverlayLayer.key])) {
            props.mapRef.current.removeLayer(window[inputOverlayLayer.key])
        } else {

            window[inputOverlayLayer.key] = L.tileLayer(inputOverlayLayer.url,
                { foo: 'license', attribution: '&copy; <a href="https://reazy.co">Reazy.co</a> contributors' });
    
            //console.log("inputOverlayLayer.key: ", inputOverlayLayer.key)
            // window[inputOverlayLayer.key] = source.getLayer(inputOverlayLayer.key)
            props.mapRef.current.getPane('pane-map-' + inputOverlayLayer.key).style.zIndex = inputOverlayLayer.zIndex + 500;
    
            props.mapRef.current.addLayer(window[inputOverlayLayer.key])
    
            window[inputOverlayLayer.key].on('loading', function (event) {
                props.store.setLoadingBarStatus(false)
            });
    
            window[inputOverlayLayer.key].on('load', function (event) {
                props.store.setLoadingBarStatus(true)
            });
        }

        let selectedOverlayLayer = toJS(inputOverlayLayer)
        let overlayList = toJS(props.store.mapAuthorizedOverlayServiceList);
        let updatedOverlayList = []

        for (let i = 0; i < overlayList.length; i++) {
            let activeStatus = (selectedOverlayLayer.key === overlayList[i].key) ? !overlayList[i].active : false;
            
            const overlayTemp = OverlayService.create({
                id: overlayList[i].id,
                name: overlayList[i].name,
                url: overlayList[i].url,
                key: overlayList[i].key,
                active: (activeStatus) ? 1 : 0,
                opacity: overlayList[i].opacity,
                checked: overlayList[i].checked,
                queryable: overlayList[i].queryable,
                zIndex: overlayList[i].zIndex,
                layers: overlayList[i].layers,
                legends: overlayList[i].legends,
                //isPublic: overlayList[i].isPublic,
                type: overlayList[i].type
            });

            //console.log("overlayTemp: ", overlayTemp)
            updatedOverlayList.push(overlayTemp)
        }

        props.store.setMapAuthorizedOverlayServiceList(updatedOverlayList)
    }

    /**
     * overlay katman şeffaflığını değiştirir
     * @param {*} event 
     * @param {*} newValue 
     * @param {*} item 
     */ 
    const handleSliderChange = (event, newValue, item) => {
        //console.log("item.key", item.key)
        //console.log("window[item.key]: ", window[item.key])
        //console.log("newValue: ", newValue / 100)

        window[item.key].setOpacity(newValue / 100)
        
        props.store.setMapAuthorizedOverlayOpacity(item.id, newValue)
    };

    /**
     * overlay katman şeffaflığı değiştirilirken kullanıcıcya yüzdenin gösterilmesini sağlar
     * @param {*} value 
     * @returns 
     */
    function getSliderValueText(value) {
        return `% ${value}`;
    }

    const createLayerItem = (provided, item, idx) => {
        return <>
            <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <Card key={idx} style={{
                    marginTop: "5px", marginBottom: "5px", paddingLeft: "0", paddingRight: "0",
                    backgroundColor: "rgba(0,0,0,0.05)", width: "100%"
                }}>
                    <CardContent style={{ padding: "10px !important", paddingRight: "0", paddingLeft: "0" }}>
                        <Grid container spacing={1}>
                        <Grid item xs={2} style={{ paddingRight: "0" }}>
                                <Tooltip title="Katmanın görünürlüğünü aç/kapat" placement="bottom">
                                    <IconButton size="small" aria-label="show/close layer" component="span" onClick={() => toggleOverlayLayer(item)}
                                        style={{ float: "right", marginTop: "10px" }}>
                                        {(item.active === 1) ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            
                            <Grid item xs={8}>
                                <p style={{ margin: "6px 0px 0px 0px", fontWeight: "bold", marginBottom: "0" }}>{item.name}</p>
                                <p style={{ fontSize: "12px", marginBottom: "5px" }}>{(item.type === 0) ? "INGRIT Map Service" : (item.type === 1) ? "WMS" : "Diğer"}</p>
                            </Grid>
                            
                            <Grid item xs={2} style={{ paddingRight: "0" }}>
                                <Tooltip title="Katman ayrıntıları" placement="bottom">
                                    <IconButton size="small" aria-label="show/close layer" component="span" 
                                        style={{ float: "right", marginTop: "10px", marginRight: "5px" }} 
                                        data-bs-toggle="collapse" data-bs-target={"#collapse-layer-" + idx}
                                        aria-expanded="true" aria-controls={"collapse-layer-" + idx} onClick={() => onClickGetLayerLegendInfo(item, idx)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        <div className="accordion" id={"layer-accordion-" + idx} >
                            <div className="accordion-item">
                                <div id={"collapse-layer-" + idx} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent={"#layer-accordion-" + idx}
                                    style={{ border: "none" }} >
                                    <div className="accordion-body">
                                        <Typography id={'opacity-slider-' & idx} style={{ marginBottom: "0", fontSize: "12px" }}>
                                            <b>Şeffaflık</b>
                                        </Typography>

                                        <Slider value={item.opacity} onChange={(event, newValue) => handleSliderChange(event, newValue, item)} step={10}
                                            marks defaultValue={100} valueLabelDisplay="auto"
                                            getAriaValueText={getSliderValueText}
                                            min={0} max={100} aria-labelledby={'opacity-slider-' + idx} />

                                        <Typography style={{ marginBottom: "5px", fontSize: "12px" }}>
                                            <b>Lejant</b>
                                        </Typography>
                                        <img src={item.legends} alt="layer-legend" style={{ height: "150px" }} />
                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </ListItem>


        </>

    }
    return (
        <Draggable draggableId={props.item.id + ""} index={props.index} isDragDisabled={props.index === activeSliderLayerItemIndex}>
            {(provided, snapshot) => (
                createLayerItem(provided, props.item, props.index)
            )}
        </Draggable>
    );
}))

export default DraggableListItem;
