import React, { useEffect, useRef } from 'react';
import { observer, inject } from "mobx-react"
import { toJS } from 'mobx';

import L from 'leaflet';
import "proj4";
import "proj4leaflet";
import 'leaflet-editable';
import 'leaflet-measure/dist/leaflet-measure.tr'
import 'leaflet-measure-path'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-plugins/layer/tile/Yandex'
import 'leaflet-plugins/layer/tile/Bing'
import 'leaflet-plugins/layer/tile/Bing.addon.applyMaxNativeZoom'

import './zoombox/selected-area-zoom/leaflet-control-boxzoom'

import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress'
import Divider from '@mui/material/Divider';

import CreateIcon from '@mui/icons-material/Create';
import SearchIcon from '@mui/icons-material/Search';
import LayersIcon from '@mui/icons-material/Layers';
import DeleteIcon from '@mui/icons-material/Delete';
import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TableChartIcon from '@mui/icons-material/TableChart';
import UserIcon from '@mui/icons-material/Person';

import { EditingLayers } from './model/EditingLayers';

import MapUtilityService from './utils/MapUtilityService';

import { MapAccountMenu } from './account/MapAccountMenu';
import { MapLayerListMenu } from './layer/MapLayerListMenu';
import { MapEditMenu } from './edit/MapEditMenu';
import { MpsZoomBoxMenu } from './zoombox/MpsZoomBoxMenu';

//extension
import config from '../config/client.json'
import MapissoAlert from '../components/common/MapissoAlert';
import { Grid } from '@mui/material';

import { RiskAPI } from '../apis/RiskAPI'
import { Risk } from '../apis/model/ingrit/Risk';
import { RiskResultMenu } from './georisk/RiskResultMenu';
import { LatLngRiskQueryMenu } from './georisk/LatLngRiskQueryMenu';
import { RiskAdresSorgulaMenu } from './georisk/RiskAdresSorgulaMenu';
import { MapUserMenu } from './georisk/MapUserMenu';

const style = {
    fillColor: '#4958ea',
    weight: 3,
    opacity: 1,
    color: '#4958ea',  //Outline color
    fillOpacity: 0.1
}

export const MpsMapViewer = inject("store")(observer((props) => {

    //const [mapRefState, setMapRef] = useState(null);

    const mapPositionArray = useRef([]);

    const editingLayers = useRef(null);
    const editedLayer = useRef(null);
    const editingGeojsonLayers = useRef(null);

    const mapRef = useRef(null);
    const baseLayerRef = useRef(null);
    const geocodeJsonRef = useRef(null);
    const queryGeojsonRef = useRef(null)
    const extentGeojsonRef = useRef(null)

    const leafletZoomBox = useRef(null)

    /** 
     * creating maps and base layer settings
     */
    useEffect(() => {
        let layer = null

        let url = ""
        if (props.baseLayer !== null && props.baseLayer !== undefined) {
            if (props.store.darkMode) {
                url = props.store.baseLayerList[2].url
                if (props.store.baseLayerList[2].access_token !== "") {
                    url = url + props.store.baseLayerList[2].access_token
                }
            } else {
                url = props.store.baseLayerList[0].url
                if (props.store.baseLayerList[0].access_token !== "") {
                    url = url + props.store.baseLayerList[0].access_token
                }
            }

            layer = L.tileLayer(url);

            //altlık harita tanımı
            baseLayerRef.current = layer

        }

        mapRef.current = L.map(props.id, {
            editable: true,
            zoomControl: false,
            crs: L.CRS.EPSG3857,
            attributionControl: false,
            center: [39.30129, 34.78271],
            zoom: 5,
            layers: [
                baseLayerRef.current
            ],
            maxZoom: 21,
            minZoom: 5
        });

        //harita ölçeği tanımı            
        L.control.scale({ imperial: false }).addTo(mapRef.current);

        leafletZoomBox.current = L.Control.boxzoom({ position: 'bottomright', keepOn: true, darkMode: props.store.darkMode })
        leafletZoomBox.current.addTo(mapRef.current);

        //harita zoom ve hareket işlemleri sonucunda harita zoom ve merkez koordinat bilgilerinin bir array halinde kaydedilmesi işlemi
        mapRef.current.on('zoomend', function (event) {
            mapPositionArray.current.push({ center: mapRef.current.getCenter(), zoom: mapRef.current.getZoom() })
        })

        mapRef.current.on('dragend', function (event) {
            mapPositionArray.current.push({ center: mapRef.current.getCenter(), zoom: mapRef.current.getZoom() })
        })

        // kullanıcı mouse hareketi yaptıkça mouse koordinat bilgisini güncellenmesi
        mapRef.current.on('mousemove', function (event) {
            //let tempCoords = proj4(epsg4326, targetCrs, [event.latlng.lng, event.latlng.lat])
            //document.getElementById('mousecoordinates').innerHTML = "X: " + tempCoords[1].toFixed(2) + " m, Y: " + tempCoords[0].toFixed(2) + " m"
            document.getElementById('mousecoordinateswGS84').innerHTML = "Enlem: " + event.latlng.lat.toFixed(5) + " , Boylam: " + event.latlng.lng.toFixed(5)
        })

        //kullanıcı ölçme işlemi yaptığında haritanın sağa doğru kayması hatasının çözümü için eklendi.
        L.Control.Measure.include({
            // set icon on the capture marker
            _setCaptureMarkerIcon: function () {
                // disable autopan
                this._captureMarker.options.autoPanOnFocus = false;

                // default function
                this._captureMarker.setIcon(
                    L.divIcon({
                        iconSize: this._map.getSize().multiplyBy(2)
                    })
                );
            },
        });

        var measureControl = new L.Control.Measure({
            position: 'bottomright',
            primaryLengthUnit: 'meters',
            secondaryLengthUnit: undefined,
            primaryAreaUnit: 'sqmeters',
            secondaryAreaUnit: undefined,
            activeColor: '#8338ec',
            completedColor: '#8338ec'
        });

        measureControl.addTo(mapRef.current);
        // ölçüm işlemi başladığında select area zoom işleminin kapatılması
        mapRef.current.on('measurestart', (e) => {
            leafletZoomBox.current.hide()
        })

        // kullanıcının get feature info butonuna tıklayınca curser tipini değiştirme
        if (props.store.mapGetFeatureInfoButtonStatus) {
            document.getElementById(props.id).style.cursor = 'crosshair'
        } else {
            document.getElementById(props.id).style.cursor = 'auto'
        }

        // kullanıcının harita üzerinde get feture info isteği yapabilmesi için gerekli tanımlar
        mapRef.current.on('click', async function (e) {
            if (props.store.mapGetFeatureInfoButtonStatus) {
                RiskAPI.getRiskByLatLng(props, e.latlng.lng, e.latlng.lat, false).then(
                    (riskApiResponse) => {
                        console.log("riskApiResponse: ", riskApiResponse)
                        if (riskApiResponse.result !== null) {
                            let resultRisk = Risk.create({
                                latitude: riskApiResponse?.result?.lat,
                                longitude: riskApiResponse?.result?.lng,
                                floodrisk: riskApiResponse?.result?.sel,
                                landsliderisk: riskApiResponse?.result?.heyelan,
                                earthquakerisk: riskApiResponse?.result?.deprem
                            })
                            props.store.setSelectedRisk(resultRisk)
                        }
                    }
                ).catch(e => {
                    console.log("e: ", e)
                })

                if (queryGeojsonRef.current !== null) {
                    mapRef.current.removeLayer(queryGeojsonRef.current)
                }

                let geojson = {
                    "type": "Feature",
                    "properties": {
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": [e.latlng.lng, e.latlng.lat]
                    }
                };

                queryGeojsonRef.current = L.geoJSON(geojson, { style: style }).addTo(mapRef.current);

                props.store.setAttributeTableDisplay(true)
            }
        })

        // Editleme katmanlarının tanımı
        editingLayers.current = L.featureGroup().addTo(mapRef.current);
        mapRef.current.pm.setGlobalOptions({
            layerGroup: editingLayers.current
        });
        mapRef.current.pm.setLang('tr');

        mapRef.current.on('pm:drawend', (e) => {

            editingLayers.current.eachLayer(layer => {

                if (layer.pm._shape === "Polygon") {
                    L.polygon(layer._latlngs)
                        .addTo(mapRef.current)
                        .showMeasurements();
                } else if (layer.pm._shape === "LineString" || layer.pm._shape === "Line") {
                    L.polyline(layer._latlngs)
                        .addTo(mapRef.current)
                        .showMeasurements();
                } else if (layer.pm._shape === "Circle") {
                    L.circle([layer._latlng.lat, layer._latlng.lng], { radius: layer._mRadius })
                        .addTo(mapRef.current)
                        .showMeasurements();
                } else if (layer.pm._shape === "Rectangle") {
                    let coordinates = layer._latlngs
                    coordinates.push(coordinates[0])
                    console.log(coordinates)
                    L.polygon(coordinates)
                        .addTo(mapRef.current)
                        .showMeasurements();
                }

                if (!(layer instanceof L.Circle) && !(layer instanceof L.CircleMarker) && layer.pm._shape !== "Text") {

                    let geojson = layer.toGeoJSON()
                    let tempEditingLayer = null
                    if (editedLayer.current !== null) {
                        geojson.properties = editedLayer.current.properties

                        tempEditingLayer = EditingLayers.create({
                            layerId: geojson.properties.layerId,
                            layerGeojson: JSON.stringify(geojson),
                            layerName: geojson.properties.layerName + (props.store.mapFilterMenuGeomEditStatus) ? " filter" : "",
                            layerType: geojson.properties.layerType
                        })

                    } else {

                        geojson.properties.layerId = layer._leaflet_id
                        geojson.properties.layerName = layer.pm._shape + " " + layer._leaflet_id
                        geojson.properties.layerType = layer.pm._shape
                        //console.log("layer.pm_shape: ", layer.pm._shape)
                        let layerName = (layer.pm._shape === "Polygon") ? "Kapalı Alan" : (layer.pm._shape === "Line") ? "Çizgi" : (layer.pm._shape === "Rectangle") ? "Dikdörtgen" :
                            (layer.pm._shape === "Circle") ? "Daire" : (layer.pm._shape === "CircleMarker") ? "Dairesel Nokta" : (layer.pm._shape === "Marker") ? "Nokta" : "Text"

                        tempEditingLayer = EditingLayers.create({
                            layerId: layer._leaflet_id,
                            layerGeojson: JSON.stringify(geojson),
                            layerName: (props.store.mapFilterMenuGeomEditStatus) ? "filter" : layerName,
                            layerType: layer.pm._shape
                        })

                    }

                    props.store.addEditingLayersList(tempEditingLayer)

                }
            })

            props.store.setMapActiveEditMode('')
        });

        mapRef.current.on('pm:globaldragmodetoggled', (e) => {
            let editingLayerList = []
            if (editingGeojsonLayers.current !== null) {
                editingGeojsonLayers.current.eachLayer(function (layer) {
                    let geojson = layer.toGeoJSON()
                    let tempEditingLayer = null
                    for (let index = 0; index < props.store.mapEditingLayersList.length; index++) {
                        if (props.store.mapEditingLayersList[index].layerId === layer._leaflet_id) {
                            tempEditingLayer = toJS(props.store.mapEditingLayersList[index])
                        }
                    }
                    tempEditingLayer.layerGeojson = JSON.stringify(geojson)
                    editingLayerList.push(tempEditingLayer)
                })
            }
            props.store.updateEditingLayers(editingLayerList)
        });

        mapRef.current.on('pm:globalcutmodetoggled', (e) => {
            let editingLayerList = []
            if (editingGeojsonLayers.current !== null) {
                editingGeojsonLayers.current.eachLayer(function (layer) {
                    let geojson = layer.toGeoJSON()
                    let tempEditingLayer = null
                    for (let index = 0; index < props.store.mapEditingLayersList.length; index++) {
                        if (props.store.mapEditingLayersList[index].layerId === layer._leaflet_id) {
                            tempEditingLayer = toJS(props.store.mapEditingLayersList[index])
                        }
                    }
                    tempEditingLayer.layerGeojson = JSON.stringify(geojson)
                    editingLayerList.push(tempEditingLayer)
                })
            }
            props.store.updateEditingLayers(editingLayerList)
        });

        mapRef.current.on('pm:globaleditmodetoggled', (e) => {
            let editingLayerList = []

            if (editingGeojsonLayers.current !== null) {
                editingGeojsonLayers.current.eachLayer(function (layer) {
                    let geojson = layer.toGeoJSON()
                    let tempEditingLayer = null
                    for (let index = 0; index < props.store.mapEditingLayersList.length; index++) {
                        if (props.store.mapEditingLayersList[index].layerId === layer._leaflet_id) {
                            tempEditingLayer = toJS(props.store.mapEditingLayersList[index])
                        }
                    }
                    tempEditingLayer.layerGeojson = JSON.stringify(geojson)
                    editingLayerList.push(tempEditingLayer)
                })
            }
            props.store.updateEditingLayers(editingLayerList)
        });

        mapRef.current.on('pm:globalrotatemodetoggled', (e) => {
            let editingLayerList = []
            if (editingGeojsonLayers.current !== null) {
                editingGeojsonLayers.current.eachLayer(function (layer) {
                    let geojson = layer.toGeoJSON()
                    let tempEditingLayer = null
                    for (let index = 0; index < props.store.mapEditingLayersList.length; index++) {
                        if (props.store.mapEditingLayersList[index].layerId === layer._leaflet_id) {
                            tempEditingLayer = toJS(props.store.mapEditingLayersList[index])
                        }
                    }
                    tempEditingLayer.layerGeojson = JSON.stringify(geojson)
                    editingLayerList.push(tempEditingLayer)
                })
            }
            props.store.updateEditingLayers(editingLayerList)
        });

        if (props.store.mapEditingLayersList.length > 0) {
            editingGeojsonLayers.current = L.featureGroup().addTo(mapRef.current);

            let updatedEditingLayers = []
            for (let index = 0; index < props.store.mapEditingLayersList.length; index++) {
                const tempDrawing = toJS(props.store.mapEditingLayersList[index]);
                let tempDrawingGeojson = JSON.parse(tempDrawing.layerGeojson)
                if (tempDrawingGeojson.geometry !== undefined) {
                    if (tempDrawingGeojson.geometry.type === "Polygon") {
                        let layerPolygon = L.polygon([MapUtilityService.flipGeojsonCoordinates(tempDrawingGeojson.geometry.coordinates[0])], style)
                            .addTo(mapRef.current)
                            .showMeasurements();
                        tempDrawing.layerId = layerPolygon._leaflet_id
                        editingGeojsonLayers.current.addLayer(layerPolygon)
                        updatedEditingLayers.push(tempDrawing)
                    } else if (tempDrawingGeojson.geometry.type === "LineString") {
                        let layerPolyline = L.polyline(MapUtilityService.flipGeojsonCoordinates(tempDrawingGeojson.geometry.coordinates), style)
                            .addTo(mapRef.current)
                            .showMeasurements();
                        tempDrawing.layerId = layerPolyline._leaflet_id
                        editingGeojsonLayers.current.addLayer(layerPolyline)
                        updatedEditingLayers.push(tempDrawing)
                    }
                }
            }
            props.store.updateEditingLayers(updatedEditingLayers)
        }

        // haritaya overlay katmanlarının listesi
        createMapOverlayServiceList();
        changeColorMeasureAndSelectZoomByDarkMode()

        //setMapRef(mapRef.current)
        mapRef.current.invalidateSize();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.mapData !== null) {
            extentGeojsonRef.current = L.geoJSON(props.mapData, { style: style });
            if (extentGeojsonRef.current !== null && extentGeojsonRef.current !== undefined) {
                mapRef.current.fitBounds(extentGeojsonRef.current.getBounds());
            }
        }
    }, [props.mapData]);

    /**
    * Dark Mode - Day Mode değişiminde altlık haritanın değişimini sağlar
    */
    useEffect(() => {
        let layer = null

        if (baseLayerRef.current !== null) {
            baseLayerRef.current.remove()
        }

        if (props.store.darkMode) {
            let url = props.store.baseLayerList[1].url
            if (props.store.baseLayerList[1].access_token !== "") {
                url = url + props.store.baseLayerList[1].access_token
            }
            console.log("url: ", url)
            layer = L.tileLayer(url, { zIndex: 1 });
            layer.addTo(mapRef.current);
        } else {

            let selectedLayer = props.store.baseLayerList[10]

            if (selectedLayer.provider === "Microsoft") {

                mapRef.current.eachLayer(function (layer) {
                    if (layer instanceof L.TileLayer) {
                        mapRef.current.removeLayer(layer);
                    }
                })

                var bingDefaults = {
                    key: selectedLayer.access_token,
                    detectRetina: false
                };

                if (selectedLayer.url === "hybrid") {

                    let bingMapsHydbridLayer = L.bingLayer(L.extend({}, bingDefaults, {
                        imagerySet: 'AerialWithLabels',
                        retinaDpi: false
                    }))
                    //setBingHybrid(bingMapsHydbridLayer)
                    bingMapsHydbridLayer.addTo(mapRef.current);
                } else if (selectedLayer.url === "road") {
                    let bingMapsRoadLayer = L.bingLayer(L.extend({}, bingDefaults, {
                        imagerySet: 'RoadOnDemand',
                        retinaDpi: false
                    }))
                    //setBingRoad(bingMapsRoadLayer)
                    bingMapsRoadLayer.addTo(mapRef.current);


                } else if (selectedLayer.url === "satellite") {
                    let bingMapsSatelliteLayer = L.bingLayer(L.extend({}, bingDefaults, {
                        imagerySet: 'Aerial',
                        retinaDpi: false
                    }))
                    //setBingSatellite(bingMapsSatelliteLayer)
                    bingMapsSatelliteLayer.addTo(mapRef.current);
                }
            } else {
                let url = props.store.baseLayerList[10].url
                if (props.store.baseLayerList[10].access_token !== "") {
                    url = url + props.store.baseLayerList[10].access_token
                }
                layer = L.tileLayer(url, { zIndex: 1 });

                mapRef.current.eachLayer(function (layer) {
                    if (layer instanceof L.TileLayer) {
                        mapRef.current.removeLayer(layer);
                    }
                })
                baseLayerRef.current = layer
                baseLayerRef.current.addTo(mapRef.current)
            }
        }

        // eslint-disable-next-line
    }, [props.store.darkMode]);

    useEffect(() => {
        createMapOverlayServiceList()
        // eslint-disable-next-line
    }, [JSON.stringify(props.store.mapAuthorizedOverlayServiceList)])

    const createMapOverlayServiceList = () => {

        if (props.store.mapAuthorizedOverlayServiceList.length > 0) {
            for (let i = 0; i < props.store.mapAuthorizedOverlayServiceList.length; i++) {

                if (window[props.store.mapAuthorizedOverlayServiceList[i].key] === undefined ||
                    !mapRef.current.hasLayer(window[props.store.mapAuthorizedOverlayServiceList[i].key])) {

                    mapRef.current.createPane('pane-map-' + props.store.mapAuthorizedOverlayServiceList[i].key);

                    addAuthorizedOverlaysToMap(props.store.mapAuthorizedOverlayServiceList[i], i)

                    if (window[props.store.mapAuthorizedOverlayServiceList[i].key] !== undefined) {
                        window[props.store.mapAuthorizedOverlayServiceList[i].key].on('loading', function (event) {
                            //console.log("***** event: ", event)
                            props.store.setLoadingBarStatus(false)
                        });
                        window[props.store.mapAuthorizedOverlayServiceList[i].key].on('load', function (event) {
                            props.store.setLoadingBarStatus(true)
                        });

                        window[props.store.mapAuthorizedOverlayServiceList[i].key].on('requeststart', function (event) {
                            //console.log("***** event: ", event)
                            props.store.setLoadingBarStatus(false)
                        });
                        window[props.store.mapAuthorizedOverlayServiceList[i].key].on('requestend', function (event) {
                            props.store.setLoadingBarStatus(true)
                        });
                    }
                } else {
                    mapRef.current.removeLayer(window[props.store.mapAuthorizedOverlayServiceList[i].key])
                    addAuthorizedOverlaysToMap(props.store.mapAuthorizedOverlayServiceList[i], i)
                    if (window[props.store.mapAuthorizedOverlayServiceList[i].key] !== undefined) {
                        window[props.store.mapAuthorizedOverlayServiceList[i].key].on('loading', function (event) {
                            props.store.setLoadingBarStatus(false)
                        });
                        window[props.store.mapAuthorizedOverlayServiceList[i].key].on('load', function (event) {
                            props.store.setLoadingBarStatus(true)
                        });
                    }
                }

            }
        }
        //mapRef.current.getPane('pane-map-reazyco-heyelan').style.zIndex = 300;
    }

    const addAuthorizedOverlaysToMap = (overlayLayer, i) => {
        window[overlayLayer.key] = L.tileLayer(overlayLayer.url,
            { foo: 'license', attribution: '&copy; <a href="https://reazy.co">Reazy.co</a> contributors' });

        window[overlayLayer.key].setOpacity(overlayLayer.opacity / 100)
        mapRef.current.getPane('pane-map-' + overlayLayer.key).style.zIndex = overlayLayer.zIndex + 300;

        if (overlayLayer.active === 1) {
            window[overlayLayer.key].addTo(mapRef.current);
        }
    }

    const onClickMapLayerButton = () => {
        props.store.setMapLayerPanel(!props.store.mapLayerPanel)
        if (props.store.mapLayerPanel) {
            props.store.setMapPaginationMenuDisplay(false)
            props.store.setMapQueryMenuDisplay(false)
            props.store.setMapEditMenuDisplay(false)
            props.store.setMapGetFeatureInfoButtonStatus(false)
            props.store.setMapSelectByAttributesDisplay(false)

        }
    }

    const changeDarkLightMode = () => {
        props.store.setDarkMode(!props.store.darkMode)
        changeColorMeasureAndSelectZoomByDarkMode()
    }

    /**
     * kullanıcın dark mode durumunu değiştirmesi durumunda ölçüm butonu ile select zoom area butonlarının renklerinin değiştirilmesi
     */
    const changeColorMeasureAndSelectZoomByDarkMode = () => {
        let divMeasureButtonArray = Array.from(document.getElementsByClassName("leaflet-control-measure-toggle"))
        let divMeasureInformationContainterArray = Array.from(document.getElementsByClassName("leaflet-control-measure-interaction"))
        if (props.store.darkMode) {
            for (let i = 0; i < divMeasureButtonArray.length; i++) {
                const element = divMeasureButtonArray[i];
                element.className = "leaflet-control-measure-toggle js-toggle leaflet-measure-darkmode"
            }
            for (let i = 0; i < divMeasureInformationContainterArray.length; i++) {
                const element = divMeasureInformationContainterArray[i];
                element.className = "leaflet-control-measure-interaction js-interaction leaflet-control-measure-interaction-darkmode"
            }
            leafletZoomBox.current.setDarkMode(true)
        } else {
            for (let i = 0; i < divMeasureButtonArray.length; i++) {
                const element = divMeasureButtonArray[i];
                element.className = "leaflet-control-measure-toggle js-toggle"
            }
            for (let i = 0; i < divMeasureInformationContainterArray.length; i++) {
                const element = divMeasureInformationContainterArray[i];
                element.className = "leaflet-control-measure-interaction js-interaction"
            }
            leafletZoomBox.current.setDarkMode(false)
        }
    }

    const onClickMapEditlemeButton = () => {
        props.store.setMapFilterMenuGeomEditStatus(false)
        mapRef.current.pm.disableDraw();
        props.store.setMapEditMenuDisplay(!props.store.mapEditMenuDisplay)
        if (props.store.mapEditMenuDisplay) {
            leafletZoomBox.current.hide()
            props.store.setMapPaginationMenuDisplay(false)
            props.store.setMapQueryMenuDisplay(false)
            props.store.setMapLayerPanel(false)
            props.store.setMapGetFeatureInfoButtonStatus(false)
            props.store.setMapSelectByAttributesDisplay(false)
        }
    }

    const deleteMapData = () => {
        if (queryGeojsonRef.current !== null) {
            mapRef.current.removeLayer(queryGeojsonRef.current)
        }
        /*(editingGeojsonLayers.current !== null) {
             mapRef.current.removeLayer(editingGeojsonLayers.current)
         }*/
        if (geocodeJsonRef.current !== null) {
            mapRef.current.removeLayer(geocodeJsonRef.current)
        }
        if (extentGeojsonRef.current !== null) {
            mapRef.current.removeLayer(extentGeojsonRef.current)
        }
    }

    const onClickGetFeatureInfoButton = () => {
        if (props.store.mapGetFeatureInfoSelectedLayer !== null) {
            props.store.setMapGetFeatureInfoButtonStatus(!props.store.mapGetFeatureInfoButtonStatus)
            if (props.store.mapGetFeatureInfoButtonStatus) {
                leafletZoomBox.current.hide()
                props.store.setMapPaginationMenuDisplay(false)
                props.store.setMapQueryMenuDisplay(false)
                props.store.setMapLayerPanel(false)
                props.store.setMapEditMenuDisplay(false)
            }
            if (props.store.mapGetFeatureInfoButtonStatus) {
                document.getElementById(props.id).style.cursor = 'crosshair'
            } else {
                document.getElementById(props.id).style.cursor = 'auto'
            }
        } else {
            props.store.setMessage("Sorgu yapmak için lütfen katman panelinden sorgulanacak katmanı seçiniz.", true)
        }
    }

    const onClickAttributeTableButton = () => {
        props.store.setAttributeTableDisplay(!props.store.mapAttributeTableDisplay)
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Backspace' || event.key === "Delete") {
                deleteMapData()
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line
    }, []);

    const onClickLatLngMenu = () => {
        props.store.setMapLatLngMenuDisplay(!props.store.mapLatLngMenuDisplay)
        props.store.setSelectedRisk(Risk.create({}))
        props.store.setAttributeTableDisplay(false)
        props.store.setMapGetFeatureInfoButtonStatus(false)
        leafletZoomBox.current.hide()
        props.store.setMapPaginationMenuDisplay(false)
        props.store.setMapQueryMenuDisplay(false)
        props.store.setMapLayerPanel(false)
        props.store.setMapEditMenuDisplay(false)
        props.store.setMapGeocoderMenuDisplay(false)
        if (props.store.mapGetFeatureInfoButtonStatus) {
            document.getElementById(props.id).style.cursor = 'crosshair'
        } else {
            document.getElementById(props.id).style.cursor = 'auto'
        }
    }

    const onClickGeocoderMenu = () => {
        props.store.setMapGeocoderMenuDisplay(!props.store.mapGeocoderMenuDisplay)
        props.store.setMapLatLngMenuDisplay(false)
        props.store.setSelectedRisk(Risk.create({}))
        props.store.setAttributeTableDisplay(false)
        props.store.setMapGetFeatureInfoButtonStatus(false)
        leafletZoomBox.current.hide()
        props.store.setMapPaginationMenuDisplay(false)
        props.store.setMapQueryMenuDisplay(false)
        props.store.setMapLayerPanel(false)
        props.store.setMapEditMenuDisplay(false)
        if (props.store.mapGetFeatureInfoButtonStatus) {
            document.getElementById(props.id).style.cursor = 'crosshair'
        } else {
            document.getElementById(props.id).style.cursor = 'auto'
        }
    }

    const onClickUserMenu = () => {
        props.store.setMapUserMenuDisplay(!props.store.mapUserMenuDisplay)
        props.store.setMapLatLngMenuDisplay(false)
        props.store.setSelectedRisk(Risk.create({}))
        props.store.setAttributeTableDisplay(false)
        props.store.setMapGetFeatureInfoButtonStatus(false)
        leafletZoomBox.current.hide()
        props.store.setMapPaginationMenuDisplay(false)
        props.store.setMapQueryMenuDisplay(false)
        props.store.setMapLayerPanel(false)
        props.store.setMapEditMenuDisplay(false)
        if (props.store.mapGetFeatureInfoButtonStatus) {
            document.getElementById(props.id).style.cursor = 'crosshair'
        } else {
            document.getElementById(props.id).style.cursor = 'auto'
        }
    }

    return (
        <>
            <div className={props.store.darkMode ? "mps-left-menu-black" : "mps-left-menu-white"}>
                <Tooltip title={config.appName} placement="right">
                    <a href={config.appBaseUrl} style={{ marginBottom: "5px" }}>
                        <img className="intro" src={"/assets/images/layers.png"} alt="mapiso-logo"
                            style={{ width: "70%", marginLeft: "15%", marginTop: "10px" }}></img>
                    </a>
                </Tooltip>

                {
                    /**
                     <Tooltip title="Menü" placement="right" onClick={onClickMapPaginationMenuButton}
                        hidden={!props.showPaginationMenu}>
                        <IconButton className={(props.store.mapPaginationMenuDisplay) ? "mps-left-menu-button-active" : "mps-left-menu-button"} >
                            <DehazeIcon style={{ color: "#fff" }} />
                        </IconButton>
                    </Tooltip>
                     */
                }

                <Tooltip title="Adres Bilgisi ile Risk Sorgula" placement='right'>
                    <IconButton className={(props.store.mapGeocoderMenuDisplay) ? "mps-left-menu-button-active" : "mps-left-menu-button"}
                        onClick={onClickGeocoderMenu}>
                        <SearchIcon style={{ color: "#fff" }}/>
                    </IconButton>
                </Tooltip>

                <Tooltip title="Enlem Boylam ile Risk Sorgula" placement='right'>
                    <IconButton className={(props.store.mapLatLngMenuDisplay) ? "mps-left-menu-button-active" : "mps-left-menu-button"}
                        onClick={onClickLatLngMenu}>
                        <LocationOnIcon style={{ color: "#fff" }}/>
                    </IconButton>
                </Tooltip>

                <Tooltip title="Haritadan Risk Sorgula" placement="right">
                    <IconButton className={(props.store.mapGetFeatureInfoButtonStatus) ? "mps-left-menu-button-active" : "mps-left-menu-button"}
                        style={{ borderRadius: "0px" }} onClick={onClickGetFeatureInfoButton}>
                        <InfoIcon style={{ color: "#fff" }}/>
                    </IconButton>
                </Tooltip>

                <Tooltip title="Katmanlar" placement="right">
                    <IconButton className={(props.store.mapLayerPanel) ? "mps-left-menu-button-active" : "mps-left-menu-button"}
                        onClick={onClickMapLayerButton}>
                        <LayersIcon style={{ color: "#fff" }} />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Renk Modunu Değiştir" placement="right">
                    <IconButton onClick={changeDarkLightMode}>
                        {(props.store.darkMode) ? <LightModeIcon style={{ color: "#fff" }} /> : <NightlightRoundIcon style={{ color: "#fff" }} />}
                    </IconButton>
                </Tooltip>

                <Tooltip title="Geometri Çizim Panelini Aç" placement="right">
                    <IconButton className={(props.store.mapEditMenuDisplay) ? "mps-left-menu-button-active" : "mps-left-menu-button"}
                        onClick={onClickMapEditlemeButton}>
                        <CreateIcon style={{ color: "#fff" }} />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Harita Ekranını Temizle" placement="right">
                    <IconButton onClick={deleteMapData}>
                        <DeleteIcon style={{ color: "#fff" }} />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Öznitelik Penceresini Aç/Kapat" placement="right">
                    <IconButton className={(props.store.mapAttributeTableDisplay) ? "mps-left-menu-button-active" : "mps-left-menu-button"}
                        onClick={onClickAttributeTableButton}>
                        <TableChartIcon style={{ color: "#fff" }} />
                    </IconButton>
                </Tooltip>

                <Divider style={{ width: "36px", marginTop: "10px", marginBottom: "10px" }} />

                {(props.store.user[0] !== undefined) ? (props.store.user[0].admin) ?
                    <Tooltip title="Kullanıcı İşlemleri" placement="right">
                        <IconButton className={(props.store.mapUserMenuDisplay) ? "mps-left-menu-button-active" : "mps-left-menu-button"}
                            onClick={onClickUserMenu}>
                            <UserIcon style={{ color: "#fff" }} />
                        </IconButton>
                    </Tooltip> : "" : ""
                }
            </div>

            <MapAccountMenu />
            
            <MapUserMenu />
            <RiskResultMenu />
            <LatLngRiskQueryMenu mapRef={mapRef} queryGeojsonRef={queryGeojsonRef} />
            <RiskAdresSorgulaMenu mapRef={mapRef} queryGeojsonRef={queryGeojsonRef} />

            <MapLayerListMenu mapRef={mapRef} mapLayerList={props.mapLayerList}
                appBaseUrl={config.appBaseUrl} selectLayerStatus={false} />

            <MapEditMenu mapRef={mapRef} editingLayers={editingLayers}
                editingGeojsonLayers={editingGeojsonLayers} />

            <MpsZoomBoxMenu mapRef={mapRef} extentGeojson={extentGeojsonRef}
                mapPositionHistory={mapPositionArray} darkMode={props.store.darkMode}
                leafletZoomBox={leafletZoomBox} />

            <Card className="mps-mousecoordinates-panel">
                <Typography variant='caption'>
                    <b>Koordinat: Coğrafi - WGS84</b>
                    <p id="mousecoordinateswGS84" style={{ marginBottom: "0" }}></p>
                </Typography>
            </Card>

            <Card className='mps-clientinfo-panel'>
                <CardContent style={{ textAlign: "center", padding: "5px" }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Typography variant='caption'>{config.clientUnitName} {new Date().getFullYear()}©</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <div id={props.id} style={{ width: props.width, height: props.height, marginLeft: "50px" }}>
                <a href="http://www.grit.com.tr/" >
                    <img src={`/assets/images/ingritlogo.png`} alt="ingrit-logo"
                        style={{ position: "absolute", bottom: "0px", zIndex: "410", height: "60px", left: "5px" }}></img>
                </a>
            </div>

            <div style={{ position: "absolute", top: "0", height: "5px", width: "100%", zIndex: "9999" }}>
                <LinearProgress hidden={props.store.loadingBarStatus} color="secondary" />
            </div>

            <MapissoAlert />
        </>
    )
}))