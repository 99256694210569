import { types } from "mobx-state-tree"

export const BaseLayer = types
    .model({
        id: types.optional(types.number, 0),
        name: types.optional(types.string, ""),
        provider: types.optional(types.string, ""),
        image: types.optional(types.string, ""),
        url: types.optional(types.string, ""),
        access_token: types.optional(types.string, "")
    }).actions(self => ({

    }))