import { api } from "./configs/AxiosConfigs"
import { defineCancelApiObject } from "./configs/AxiosUtils"

import config from '../config/client.json'


export const UserAPI = {
    login: async function (props, email, password, cancel = false) {
        props.store.setLoadingBarStatus(false)
        const basicAuth = btoa(email + ":" + password)
        const response = await api.request({
            url: `map/v1/user`,
            method: "PUT",
            headers: { 'Authorization': "Basic " + basicAuth },
            signal: cancel ? cancelApiObject[this.login.name].handleRequestCancellation().signal : undefined,
        })
        props.store.setLoadingBarStatus(true)
        return response.data
    },
    logout: async function (props, token, cancel = false) {
        props.store.setLoadingBarStatus(false)
        const response = await api.request({
            url: `map/v1/user/logout/${token}`,
            method: "PUT",
            data: {},
            signal: cancel ? cancelApiObject[this.logout.name].handleRequestCancellation().signal : undefined,
        })
        props.store.setLoadingBarStatus(true)
        return response.data
    },
    getUserList: async function (props, cancel = false) {
        props.store.setLoadingBarStatus(false)
        const response = await api.request({
            url: `${config.serviceUrl}/map/v1/user`,
            method: "GET",
            headers: { 'Authorization': "Bearer " + props.store.token, 'Content-Type': "application/json" },
            data: null,
            signal: cancel ? cancelApiObject[this.getUserList.name].handleRequestCancellation().signal : undefined,
        })
        props.store.setLoadingBarStatus(true)
        return response.data
    },
    deleteUser: async function (props, userId, cancel = false) {
        props.store.setLoadingBarStatus(false)
        const response = await api.request({
            url: `map/v1/user/${userId}`,
            method: "DELETE",
            headers: { 'Authorization': "Bearer " + props.store.token, 'Content-Type': "application/json" },
            signal: cancel ? cancelApiObject[this.logout.name].handleRequestCancellation().signal : undefined,
        })
        props.store.setLoadingBarStatus(true)
        return response.data
    },
    addUser: async function (props, user, cancel = false) {
        props.store.setLoadingBarStatus(false)
        const response = await api.request({
            url: `map/v1/user`,
            method: "POST",
            data: user,
            headers: { 'Authorization': "Bearer " + props.store.token, 'Content-Type': "application/json" },
            signal: cancel ? cancelApiObject[this.addUser.name].handleRequestCancellation().signal : undefined,
        })
        props.store.setLoadingBarStatus(true)
        return response.data
    },
    updateUser: async function (props, userId, user, cancel = false) {
        try {
            props.store.setLoadingBarStatus(false)
            const response = await api.request({
                url: `map/v1/user/${userId}`,
                method: "PUT",
                data: user,
                headers: { 'Authorization': "Bearer " + props.store.token, 'Content-Type': "application/json" },
                signal: cancel ? cancelApiObject[this.updateUser.name].handleRequestCancellation().signal : undefined,
            })
            props.store.setLoadingBarStatus(true)
            return response.data
        } catch (error) {
            props.store.setLoadingBarStatus(true)
            return error
        }

    }
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(UserAPI) 