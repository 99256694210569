import { types } from "mobx-state-tree"

export const Layer = types
    .model({
        id: types.optional(types.number, -1),
        layerId: types.optional(types.number, -1),
        name: types.optional(types.string,""),
        type: types.optional(types.number, -1),
        serviceUrl: types.optional(types.string, ""),
        serviceKey: types.optional(types.string, ""),
    }).actions(self => ({

    }))