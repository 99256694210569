import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { ThemeProvider } from '@mui/material/styles';

import CssBaseline from "@mui/material/CssBaseline";

import HaritaPage from './pages/HaritaPage';
import RaporPage from './pages/RaporPage';
import LoginPage from './pages/LoginPage';

import darkTheme from './themes/dark-theme'; 
import lightTheme from './themes/light-theme';

class App extends Component {

  render() {
    return (
      <ThemeProvider theme={(this.props.store.darkMode) ? darkTheme : lightTheme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route exact path="/harita" element={<HaritaPage/>}/>
            <Route exact path="/" element={<HaritaPage/>}/>
            <Route exact path="/kullanici-girisi" element={<LoginPage/>}/>
            <Route exact path="/rapor" element={<RaporPage/>}/>
          </Routes>
        </Router>
      </ThemeProvider>
    );
  }
}

export default inject("store")(observer(App));