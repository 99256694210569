import { api } from "./configs/AxiosConfigs"
import { defineCancelApiObject } from "./configs/AxiosUtils"

export const RiskAPI = {
    getRiskByLatLng: async function (props, latitude, longitude, cancel = false) {
        props.store.setLoadingBarStatus(false)
        const response = await api.request({
            url: `https://risk.reazy.co/v1/data/${props.store.apikey}/?lng=${longitude}&lat=${latitude}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.getRiskByLatLng.name].handleRequestCancellation().signal : undefined,
        })
        props.store.setLoadingBarStatus(true)
        return response.data
    }
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(RiskAPI) 