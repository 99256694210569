import React, { useState } from 'react';
import { observer, inject } from "mobx-react"
import { toJS } from 'mobx';

import L from 'leaflet';
import tokml from "geojson-to-kml"

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import CloseIcon from '@mui/icons-material/Close';
import WestTwoToneIcon from '@mui/icons-material/WestTwoTone';
import { TbLine, TbPolygon } from "react-icons/tb";
import {
    RiMapPinAddFill, RiEditCircleFill,
    RiRecordCircleLine, RiMore2Fill,
    RiText, RiShape2Line, RiEarthFill, RiDeleteBinLine,
    RiEdit2Line, RiDragMove2Line, RiProfileFill,
    RiScissorsCutFill, RiRestartLine, RiSave2Line
} from "react-icons/ri";

import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import { Paper, Slide } from '@mui/material';

import Draggable from 'react-draggable';

const style = {
    fillColor: '#631ae2',
    weight: 3,
    opacity: 1,
    color: '#631ae2',  //Outline color
    fillOpacity: 0.1
}

export const MapEditMenu = inject("store")(observer((props) => {

    const [showMapEditSaveButton, setShowMapEditSaveButton] = useState(false)

    const [cizimIsimGuncelleMenuDisplay, setCizimIsimGuncelleMenuDisplay] = useState(false)
    const [cizimName, setCizimName] = useState("")
    const [selectedEditLayer, setSelectedEditLayer] = useState(null)
    const [editDownloadAnchorEl, setEditDownloadAnchorEl] = useState(null)
    const editDownloadMenuDisplay = Boolean(editDownloadAnchorEl);

    const onClickCloseMapEditMenu = () => {
        props.store.setMapEditMenuDisplay(false)
    }

    const onClickEnableDrawText = () => {
        props.mapRef.current.pm.enableDraw('Text', { textOptions: { text: '' } });
    }

    const onClickEnableDrawPoint = () => {

        props.store.setMapFilterMenuGeomEditStatus(false)

        if (props.store.mapActiveEditMode === 'Point') {
            props.store.setMapActiveEditMode('')
            props.mapRef.current.pm.disableDraw();
        } else {
            props.store.setMapActiveEditMode('Point')
            props.mapRef.current.pm.enableDraw('Marker', {
                snappable: true,
                snapDistance: 20,
            });
        }
        // props.mapRef.current.pm.disableGlobalRemovalMode()
    }

    const onClickEnableDrawPolygon = () => {

        props.store.setMapFilterMenuGeomEditStatus(false)
        if (props.store.mapActiveEditMode === 'Polygon') {
            props.store.setMapActiveEditMode('')
            props.mapRef.current.pm.disableDraw();
        } else {
            props.store.setMapActiveEditMode('Polygon')
            props.mapRef.current.pm.enableDraw('Polygon', {
                snappable: true,
                snapDistance: 20,
            });
        }
        //props.mapRef.current.pm.disableGlobalRemovalMode()
    }

    const onClickEnableDrawRectangle = () => {

        props.store.setMapFilterMenuGeomEditStatus(false)
        if (props.store.mapActiveEditMode === 'Rectangle') {
            props.store.setMapActiveEditMode('')
            props.mapRef.current.pm.disableDraw();
        } else {
            props.store.setMapActiveEditMode('Rectangle')
            props.mapRef.current.pm.enableDraw('Rectangle', {
                snappable: true,
                snapDistance: 20,
            });
        }
        //props.mapRef.current.pm.disableGlobalRemovalMode()
    }

    const onClickEnableDrawPolyline = () => {

        props.store.setMapFilterMenuGeomEditStatus(false)
        if (props.store.mapActiveEditMode === 'Line') {
            props.store.setMapActiveEditMode('')
            props.mapRef.current.pm.disableDraw();
        } else {
            props.store.setMapActiveEditMode('Line')
            props.mapRef.current.pm.enableDraw('Line', {
                snappable: true,
                snapDistance: 20,
            });
        }
        //props.mapRef.current.pm.disableGlobalRemovalMode()
    }

    //onClickEnableCircle

    const onClickEnableDrawCircle = () => {

        props.store.setMapFilterMenuGeomEditStatus(false)
        if (props.store.mapActiveEditMode === 'Circle') {
            props.store.setMapActiveEditMode('')
            props.mapRef.current.pm.disableDraw();
        } else {
            props.store.setMapActiveEditMode('Circle')
            props.mapRef.current.pm.enableDraw('Circle', {
                snappable: true,
                snapDistance: 20,
            });
        }
        //props.mapRef.current.pm.disableGlobalRemovalMode()
    }

    //CircleMarker
    const onClickEnableDrawCircleMarker = () => {

        props.store.setMapFilterMenuGeomEditStatus(false)
        if (props.store.mapActiveEditMode === 'CircleMarker') {
            props.store.setMapActiveEditMode('')
            props.mapRef.current.pm.disableDraw();
        } else {
            props.store.setMapActiveEditMode('CircleMarker')
            props.mapRef.current.pm.enableDraw('CircleMarker', {
                snappable: true,
                snapDistance: 20,
            });
        }
        //props.mapRef.current.pm.disableGlobalRemovalMode()
    }

    const onClickEnableDelete = () => {

        props.store.setMapFilterMenuGeomEditStatus(false)
        if (props.store.mapActiveEditMode === 'Delete') {
            props.store.setMapActiveEditMode('')
            setShowMapEditSaveButton(false)
            props.mapRef.current.pm.disableGlobalRemovalMode()
        } else {
            setShowMapEditSaveButton(true)
            props.mapRef.current.pm.enableGlobalRemovalMode();
            props.store.setMapActiveEditMode('Delete')
        }

    }

    const onClickEnableEdit = () => {

        props.store.setMapFilterMenuGeomEditStatus(false)
        if (props.store.mapActiveEditMode === "Edit") {
            setShowMapEditSaveButton(false)
            props.store.setMapActiveEditMode('')
            props.mapRef.current.pm.disableGlobalEditMode()
        } else {
            setShowMapEditSaveButton(true)
            props.mapRef.current.pm.enableGlobalEditMode(null);
            props.store.setMapActiveEditMode('Edit')
        }
    }

    const onClickEnableMove = () => {
        props.store.setMapFilterMenuGeomEditStatus(false)
        if (props.store.mapActiveEditMode === 'Move') {
            props.store.setMapActiveEditMode('')
            setShowMapEditSaveButton(false)
            props.mapRef.current.pm.disableGlobalDragMode()
        } else {
            props.store.setMapActiveEditMode('Move')
            setShowMapEditSaveButton(true)
            props.mapRef.current.pm.enableGlobalDragMode();
        }
    }

    const onClickEnableCut = () => {
        props.store.setMapFilterMenuGeomEditStatus(false)
        if (props.store.mapActiveEditMode === 'Cut') {
            props.store.setMapActiveEditMode('')
            setShowMapEditSaveButton(false)
            props.mapRef.current.pm.disableGlobalCutMode()
        } else {
            props.store.setMapActiveEditMode('Cut')
            setShowMapEditSaveButton(true)
            props.mapRef.current.pm.enableGlobalCutMode({
                allowSelfIntersection: false,
            });
        }
    }

    const onClickEnableRotate = () => {
        props.store.setMapFilterMenuGeomEditStatus(false)
        if (props.store.mapActiveEditMode === 'Rotate') {
            props.store.setMapActiveEditMode('')
            setShowMapEditSaveButton(false)
            props.mapRef.current.pm.disableGlobalRotateMode()
        } else {
            props.store.setMapActiveEditMode('Rotate')
            setShowMapEditSaveButton(true)
            props.mapRef.current.pm.enableGlobalRotateMode()
        }
    }

    const onClickEditsSaveButton = () => {
        props.store.setMapFilterMenuGeomEditStatus(false)
        if (props.store.mapActiveEditMode === "Edit") {
            props.mapRef.current.pm.disableGlobalEditMode()
        } else if (props.store.mapActiveEditMode === "Move") {
            props.mapRef.current.pm.disableGlobalDragMode()
        } else if (props.store.mapActiveEditMode === "Cut") {
            props.mapRef.current.pm.disableGlobalCutMode()
        } else if (props.store.mapActiveEditMode === "Rotate") {
            props.mapRef.current.pm.disableGlobalRotateMode()
        }
        props.store.setMapActiveEditMode('')
        setShowMapEditSaveButton(false)

        props.editingLayers.current.eachLayer(layer => {
            console.info(layer._latlngs)
        })

        props.store.setMessage("Yaptığınız değişiklikler başarıyla kaydedildi.", true, "success")
    }

    const onClickEditingDelete = (inputLayer, rowId) => {
        props.store.setMapFilterMenuGeomEditStatus(false)
        console.log("props.editingGeojsonLayers.current: ", props.editingGeojsonLayers.current)
        props.editingGeojsonLayers.current?.eachLayer(function (layer) {
            if (layer._leaflet_id === inputLayer.layerId) {
                props.editingGeojsonLayers.current.removeLayer(layer)
            }
        })
        props.store.deleteEditingLayer(rowId)
    }

    const onClickOpenCizimIsımGuncelleMenu = (layer, rowId) => {
        setCizimIsimGuncelleMenuDisplay(true)
        setSelectedEditLayer(layer)
        setCizimName(layer.layerName)

    }

    const onClickCloseIsimGuncelleMenu = () => {
        setCizimIsimGuncelleMenuDisplay(false)
    }

    const onChangeCizimTextInput = (event) => {
        setCizimName(event.target.value)
    }

    const onClickGuncelleCizimName = () => {

        let tempEditingLayerList = toJS(props.store.mapEditingLayersList)
        for (let i = 0; i < tempEditingLayerList.length; i++) {
            const tempEditingLayer = tempEditingLayerList[i];
            if (tempEditingLayer.layerId === selectedEditLayer.layerId) {
                tempEditingLayer.layerName = cizimName
            }
        }

        props.store.updateEditingLayers(tempEditingLayerList)

    }

    const onClickOpenEditDownloadMenu = (event, layer) => {
        setEditDownloadAnchorEl(event.currentTarget)
        setSelectedEditLayer(layer)
    }

    const onClickCloseEditDownloadMenu = (event) => {
        setEditDownloadAnchorEl(null)
    }

    const downloadKMLFile = () => {
        let geojsonLayer = JSON.parse(selectedEditLayer.layerGeojson)
        geojsonLayer.properties.layerName = selectedEditLayer.layerName
        let kml = tokml(geojsonLayer);

        const element = document.createElement("a");
        const file = new Blob([kml], {
            type: "kml"
        });
        element.href = URL.createObjectURL(file);
        element.download = selectedEditLayer.layerName + ".kml";
        document.body.appendChild(element);
        element.click();

    }

    const downloadGeojsonFile = () => {
        let geojsonLayer = JSON.parse(selectedEditLayer.layerGeojson)
        geojsonLayer.properties.layerName = selectedEditLayer.layerName

        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(geojsonLayer)], {
            type: "geojson"
        });
        element.href = URL.createObjectURL(file);
        element.download = selectedEditLayer.layerName + ".json";
        document.body.appendChild(element);
        element.click();
    }

    const onClickEditingLayersItem = (layer) => {
        let selectedLayerGeojson = JSON.parse(layer.layerGeojson)
        let selectedLayerGeojsonLayer = L.geoJSON(selectedLayerGeojson, { style: style });
        props.mapRef.current.fitBounds(selectedLayerGeojsonLayer.getBounds());
    }

    const generateEditingLayersList = () => {
        return (props.store.mapEditingLayersList !== undefined && props.store.mapEditingLayersList !== null) ?
            props.store.mapEditingLayersList.map((value, idx) =>
                <ListItem key={value.layerId} className="editingLayerListItem"
                    secondaryAction={
                        <>
                            <Tooltip title="Çizim ismini güncelle">
                                <IconButton edge="end" color="primary" size="small" aria-label="delete" onClick={() => onClickOpenCizimIsımGuncelleMenu(value, idx)}>
                                    <UpdateIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Çizim sil">
                                <IconButton edge="end" color="primary" size="small" aria-label="delete" onClick={() => onClickEditingDelete(value, idx)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Çizim İndirme Seçenekleri">
                                <IconButton id="download-edits" color="primary" size="small" onClick={(event) => onClickOpenEditDownloadMenu(event, value)}>
                                    <RiMore2Fill />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="download-edit-menu"
                                anchorEl={editDownloadAnchorEl}
                                open={editDownloadMenuDisplay}
                                onClose={onClickCloseEditDownloadMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'download-edits',
                                }}
                            >
                                <MenuItem onClick={() => downloadKMLFile()}> <RiEarthFill /> KML</MenuItem>
                                <MenuItem onClick={() => downloadGeojsonFile()}> <RiProfileFill /> Geojson</MenuItem>
                            </Menu>
                        </>
                    }>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: "#fecece" }} variant="rounded" sizes='small' >
                            {(value.layerType === "Polygon") ?
                                <TbPolygon color='#F73F3F' /> : (value.layerType === "Line") ? <TbLine color="#F73F3F" /> :
                                    (value.layerType === "Marker") ? <RiMapPinAddFill color="#F73F3F" /> : (value.layerType === "Rectangle") ?
                                        <RiShape2Line color="#F73F3F" /> : (value.layerType === "Circle") ? <RiEditCircleFill color="#F73F3F" /> :
                                            (value.layerType === "Text") ? <RiText color="#F73F3F" /> : (value.layerType === "CircleMarker") ? <RiRecordCircleLine color="#F73F3F" /> : null
                            }
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText secondary={value.layerName} onClick={() => onClickEditingLayersItem(value)} />
                </ListItem>
            ) : null;
    }

    return (
        <>
            <Slide direction="right" in={props.store.mapEditMenuDisplay} mountOnEnter >
                <Paper className="mps-left-panel" style={{ borderRadius: "0 !important", width: "300px", height: "100vh" }} elevation={0}>
                    <Card style={{ height: "100vh" }}>
                        <CardHeader subheader={<Typography variant='body1'>Çizim Menüsü</Typography>} action={
                            <IconButton aria-label="settings" onClick={onClickCloseMapEditMenu}>
                                <WestTwoToneIcon style={{ color: "#000" }} />
                            </IconButton>
                        }></CardHeader>
                        <CardContent style={{ paddingTop: "10px" }}>
                            <Grid container spacing={1} style={{ width: "100%", marginTop: "0", borderBottom: "1px solid rgba(234, 234, 234, 0.37)", paddingBottom: "10px" }}>
                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                    <Tooltip title="Nokta Çizme Aracı" placement="bottom">
                                        <IconButton color="primary" aria-label="Nokta Çiz" size="medium"
                                            style={{ background: "#fecece", border: (props.store.mapActiveEditMode === "Point") ? "2px solid #631ae2" : "2px solid transparent" }}
                                            onClick={onClickEnableDrawPoint}>
                                            <RiMapPinAddFill color='#F73F3F' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='subtitle2'>Nokta</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                    <Tooltip title="Çizgi Çizme Aracı" placement="bottom">
                                        <IconButton color="primary" aria-label="Çizgi Çiz" size="medium" style={{ background: "#fecece", border: (props.store.mapActiveEditMode === "Line") ? "2px solid #631ae2" : "2px solid transparent" }}
                                            onClick={onClickEnableDrawPolyline}>
                                            <TbLine color='#F73F3F' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='subtitle2'>Çizgi</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                    <Tooltip title="Alan Çizme Aracı" placement="bottom">
                                        <IconButton color="primary" aria-label="Alan Çizme Aracı" size="medium" style={{ background: "#fecece", border: (props.store.mapActiveEditMode === "Polygon") ? "2px solid #631ae2" : "2px solid transparent" }}
                                            onClick={onClickEnableDrawPolygon} >
                                            <TbPolygon color='#F73F3F' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='subtitle2'>Alan</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                    <Tooltip title="Yazı Ekleme Aracı" placement="bottom">
                                        <IconButton color="primary" aria-label="Yazı Ekleme Aracı" size="medium" style={{ background: "#fecece", border: (props.store.mapActiveEditMode === "Text") ? "2px solid #631ae2" : "2px solid transparent" }}
                                            onClick={onClickEnableDrawText}>
                                            <RiText color='#F73F3F' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='subtitle2'>Yazı</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                    <Tooltip title="Daire Çizme Aracı" placement="bottom">
                                        <IconButton color="primary" aria-label="Daire Çizme Aracı" size="medium" style={{ background: "#fecece", border: (props.store.mapActiveEditMode === "Circle") ? "2px solid #631ae2" : "2px solid transparent" }}
                                            onClick={onClickEnableDrawCircle}>
                                            <RiEditCircleFill color='#F73F3F' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='subtitle2'>Daire</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                    <Tooltip title="Dikdörtgen Çizme Aracı" placement="bottom">
                                        <IconButton color="primary" aria-label="Dikdörtgen Çizme Aracı" size="medium" style={{ background: "#fecece", border: (props.store.mapActiveEditMode === "Rectangle") ? "2px solid #631ae2" : "2px solid transparent" }}
                                            onClick={onClickEnableDrawRectangle}>
                                            <RiShape2Line color='#F73F3F' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='subtitle2'>Dörtgen</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                    <Tooltip title="Daire Şeklinde Noktası Çizme Aracı" placement="bottom">
                                        <IconButton color="primary" aria-label="Daire Şeklinde Noktası Çizme Aracı" size="medium" style={{ background: "#fecece", border: (props.store.mapActiveEditMode === "CircleMarker") ? "2px solid #631ae2" : "2px solid transparent" }}
                                            onClick={onClickEnableDrawCircleMarker}>
                                            <RiRecordCircleLine color='#F73F3F' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='subtitle2'>D. Nokta</Typography>
                                </Grid>

                                <Grid item xs={3} style={{ textAlign: "center" }}>
                                    <Tooltip title="Sil" placement="bottom">
                                        <IconButton color="primary" aria-label="Sil" size="medium" style={{ background: "#fecece", border: "2px solid transparent" }}
                                            onClick={onClickEnableDelete}>
                                            <RiDeleteBinLine color='#F73F3F' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='subtitle2'>Sil</Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} style={{ width: "100%", marginTop: "10px", borderBottom: "1px solid rgba(234, 234, 234, 0.37)", paddingBottom: "10px", paddingLeft: "10px", paddingRight: "10px" }}>
                                <Grid item xs={2} style={{ textAlign: "center", padding: 0 }}>
                                    <Tooltip title="Editleme Modunu Aç/Kapa" placement="bottom">
                                        <IconButton color="primary" aria-label="Editleme Modunu Aç/Kapa" size="small"
                                            style={{ background: "#fecece", border: (props.store.mapActiveEditMode === "Edit") ? "2px solid #631ae2" : "2px solid transparent" }}
                                            onClick={onClickEnableEdit}>
                                            <RiEdit2Line color='#F73F3F' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='subtitle2' style={{ fontSize: "12px" }}>Düzenle</Typography>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "center", padding: 0 }}>
                                    <Tooltip title="Taşıma Modunu Aç/Kapa" placement="bottom">
                                        <IconButton color="primary" aria-label="Taşıma Modunu Aç/Kapa" size="small" style={{ background: "#fecece", border: (props.store.mapActiveEditMode === "Move") ? "2px solid #631ae2" : "2px solid transparent" }}
                                            onClick={onClickEnableMove}>
                                            <RiDragMove2Line color='#F73F3F' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='subtitle2' style={{ fontSize: "12px" }}>Taşı</Typography>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "center", padding: 0 }}>
                                    <Tooltip title="Kesme Modunu Aç/Kapa" placement="bottom">
                                        <IconButton color="primary" aria-label="Kesme Modunu Aç/Kapa" size="small" style={{ background: "#fecece", border: (props.store.mapActiveEditMode === "Cut") ? "2px solid #631ae2" : "2px solid transparent" }}
                                            onClick={onClickEnableCut}>
                                            <RiScissorsCutFill color='#F73F3F' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='subtitle2' style={{ fontSize: "12px" }}>Kes</Typography>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "center", padding: 0 }}>
                                    <Tooltip title="Döndürme Modunu Aç/Kapa" placement="bottom">
                                        <IconButton color="primary" aria-label="Döndürme Modunu Aç/Kapa" size="small" style={{ background: "#fecece", border: (props.store.mapActiveEditMode === "Rotate") ? "2px solid #631ae2" : "2px solid transparent" }}
                                            onClick={onClickEnableRotate}>
                                            <RiRestartLine color='#F73F3F' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='subtitle2' style={{ fontSize: "12px" }}>Döndür</Typography>
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: "right", padding: 0 }}>
                                    <Tooltip title="Değişiklikleri Kaydet" placement="bottom">
                                        <span>
                                            <IconButton color="primary" aria-label="Değişiklikleri Kaydet" size="small" style={{ background: "#fecece", border: "2px solid transparent" }}
                                                disabled={!showMapEditSaveButton}
                                                onClick={onClickEditsSaveButton}>
                                                <RiSave2Line color='#F73F3F' />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Typography variant='subtitle2' style={{ fontSize: "12px" }}>Kaydet</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                    <Typography variant='caption' style={{ color: "gray" }}>
                                        < WarningIcon style={{ color: "#ecb50f", fontSize: "16px" }} /> Geometri tipi; <b>yazı, daire ve daire noktası</b> olan geometriler kaydedilmez</Typography>
                                </Grid>
                            </Grid>

                            <Typography variant="body1" style={{ marginTop: "15px", fontWeight: "bold" }}>Kayıtlı Çizim Listesi</Typography>
                            <List dense={true} style={{ height: "calc(100vh - 435px)", overflowY: "auto", overflowX: "hidden" }}>
                                {generateEditingLayersList()}
                            </List>

                        </CardContent>
                    </Card>

                </Paper>
            </Slide>
            <Draggable>

                <Card className="mps-attribute-table-container" hidden={(cizimIsimGuncelleMenuDisplay) ? false : true} >
                    <CardHeader subheader={
                        <Typography variant='body1'>
                            Çizim İsmi Güncelle Menüsü
                        </Typography>
                    } action={
                        <IconButton aria-label="settings" onClick={onClickCloseIsimGuncelleMenu}>
                            <CloseIcon />
                        </IconButton>
                    }></CardHeader>
                    <CardContent style={{ paddingBottom: "0", padding: "8px" }}>
                        <TextField value={cizimName} variant="filled" label="Çizim İsmi" onChange={onChangeCizimTextInput} style={{ width: "100%" }} />
                        <Button variant="contained" onClick={() => onClickGuncelleCizimName()} style={{ float: "right", marginTop: "10px", marginBottom: "10px" }}>
                            <UpdateIcon /> Güncelle
                        </Button>
                    </CardContent>
                </Card>
            </Draggable>

        </>
    )

}))