import React, { useState } from 'react';
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip'
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import PersonIcon from '@mui/icons-material/Person'
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

import { UserAPI } from '../../apis/UserAPI'

export const MapAccountMenu = inject("store")(observer((props) => {

    const navigate = useNavigate();
    const [accountMenuDisplay, setAccountMenuDisplay] = useState(false)
    const [loginMenuDisplay, setLoginMenuDisplay] = useState(false)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const onClickLogin = async () => {
        props.store.setLoadingBarStatus(false)

        if (email !== "" && password !== "") {
            let userLoginResponse = await UserAPI.login(props, email, password, false)

            if (userLoginResponse !== null) {
                if (userLoginResponse.status === 200) {
                    let user = userLoginResponse.data
                    props.store.setUser(user)
                    props.store.setToken(user.token)
                    props.store.setUserLogged()
                    setLoginMenuDisplay(false)
                    setAccountMenuDisplay(false)
                    props.store.setMessage("Başarılı bir şekilde giriş yaptınız.", true, "success")
                    window.location.reload()
                } else if (userLoginResponse.status === 401) {
                    props.store.setMessage("Giriş yapmaya yetkili değilsiniz.", true, "error")
                } else {
                    props.store.setMessage("Giriş yapamadınız.", true, "error")
                }
            } else {
                props.store.setMessage("Servis altyapısında hata oluştu.", true, "error")
            }
        } else {
            props.store.setMessage("Giriş yapmak için email ve şifre bilgilerinizi girmeniz gerekmektedir.", true, "error")
        }

        props.store.setLoadingBarStatus(true)
    }

    const onKeyPressHandler = async (target) => {
        if (target.charCode === 13) {
            await onClickLogin()
        }
    }

    const onLoginTextInputChange = (event) => {
        if (event.target.name === "email") {
            setEmail(event.target.value)
        } else if (event.target.name === "password") {
            setPassword(event.target.value)
        }
    }

    const onClickAccountMenu = () => {
        setAccountMenuDisplay(!accountMenuDisplay)
    }

    const onClickLogout = async () => {
        props.store.setLoadingBarStatus(false)
        //await UserAPI.logOut(props, false)

        props.store.setUserLogout()
        props.store.setMapAuthorizedOverlayServiceList([])
        props.store.setOverlayServiceList([])
        props.store.setLoadingBarStatus(true)
        setAccountMenuDisplay(false)
        navigate("/kullanici-girisi")
    }

    const onClickOpenLoginMenu = () => {
        navigate("/kullanici-girisi")
    }

    const onCloseLoginMenu = () => {
        setLoginMenuDisplay(false)
    }

    return (
        <>
            <Tooltip title="Kullanıcı Menüsü" placement='bottom'>

                <Avatar className='profile-menu'
                    style={{ width: "50px", height: "50px" }}
                    onClick={onClickAccountMenu}>
                    {props.store.isLogin ? <Typography variant='h6'>{props.store.user[0]?.name?.charAt(0) + props.store.user[0]?.surname?.charAt(0)}</Typography>
                        : <PersonIcon fontSize="inherit" style={{color: "#fff"}}/>} 
                </Avatar>
            </Tooltip>

            <Dialog open={loginMenuDisplay} onClose={onCloseLoginMenu} width={'sm'}
                aria-labelledby="dialog-login" aria-describedby="dialog-login-description">
                <DialogTitle id="dialog-login">
                    {"Kullanıcı Girişi"}
                </DialogTitle>
                <DialogContent>
                    <p style={{ fontWeight: "bold", marginTop: "10px" }}>E-posta adresiniz</p>
                    <TextField name="email" value={email} variant="filled" label="E-posta" onKeyPress={onKeyPressHandler}
                        style={{ width: "350px" }} onChange={onLoginTextInputChange} />
                    <p style={{ fontWeight: "bold", marginTop: "25px" }}>Şifreniz</p>
                    <TextField name="password" value={password} variant="filled" type="password" onKeyPress={onKeyPressHandler}
                        label="Şifre" style={{ width: "350px", marginBottom: "20px" }} onChange={onLoginTextInputChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseLoginMenu}>Kapat</Button>
                    <Button onClick={onClickLogin} autoFocus variant='contained' style={{ marginRight: "15px" }}>
                        GİRİŞ YAP
                    </Button>
                </DialogActions>
            </Dialog>

            <Card className="mps-account-menu" style={{ display: (accountMenuDisplay) ? "block" : "none", borderRadius: "0 !important" }}>
                <CardContent style={{ padding: "10px" }}>

                    {(!props.store.isLogin) ?
                        <MenuList>
                            <MenuItem onClick={onClickOpenLoginMenu}>
                                <ListItemIcon>
                                    <LoginIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Giriş Yap</ListItemText>
                            </MenuItem>
                            
                        </MenuList>
                        :
                        <MenuList>
                            <MenuItem onClick={onClickLogout}>
                                <ListItemIcon>
                                    <LogoutIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Çıkış Yap</ListItemText>
                            </MenuItem>

                        </MenuList>
                    }

                </CardContent>
            </Card>
        </>
    )

}))