import React, { useState } from 'react';
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import L from 'leaflet';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Avatar, Button, TextField } from '@mui/material';
import { Paper, Slide } from '@mui/material';

import WestTwoToneIcon from '@mui/icons-material/WestTwoTone';

import { RiskAPI } from '../../apis/RiskAPI';
import { Risk } from '../../apis/model/ingrit/Risk';

const style = {
    fillColor: '#E21A1A',
    weight: 1,
    opacity: 1,
    color: '#E21A1A',  //Outline color
    fillOpacity: 0
}

export const LatLngRiskQueryMenu = inject("store")(observer((props) => {

    const navigate = useNavigate();

    const [lat, setLat] = useState(0)
    const [lng, setLng] = useState(0)

    /*useEffect(() => {

        console.log("props.store.selectedRisk: ", props.store.selectedRisk[0])

        if(props.store.selectedRisk[0]?.latitude !== 0 && props.store.selectedRisk[0]?.longitude !== 0){
            setLat(props.store.selectedRisk[0]?.latitude)
            setLng(props.store.selectedRisk[0]?.longitude)

            let geojson = {
                "type": "Feature",
                "properties": {
                },
                "geometry": {
                    "type": "Point",
                    "coordinates": [props.store.selectedRisk[0]?.longitude, props.store.selectedRisk[0]?.latitude]
                }
            };

            console.log("props.mapRef.current: ", props.mapRef.current)

            if(props.mapRef.current !== null) {
                props.queryGeojsonRef.current = L.geoJSON(geojson, { style: style }).addTo(props.mapRef.current);
                props.mapRef.current.fitBounds(props.queryGeojsonRef.current.getBounds(), { maxZoom: 15 });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) */

    const onClickCloseEnlemBoylamSorgulamaMenu = () => {
        props.store.setMapLatLngMenuDisplay(false)
        setLat(0)
        setLng(0)
        if (props.queryGeojsonRef.current !== null) {
            props.mapRef.current.removeLayer(props.queryGeojsonRef.current)
        }
    }

    const onClickLatLngSorgula = async () => {
        RiskAPI.getRiskByLatLng(props, lng, lat, false).then(
            (riskApiResponse) => {
                props.store.setLoadingBarStatus(false)
                if (riskApiResponse.result !== null) {
                    let resultRisk = Risk.create({
                        latitude: riskApiResponse?.result?.lat,
                        longitude: riskApiResponse?.result?.lng,
                        floodrisk: riskApiResponse?.result?.sel,
                        landsliderisk: riskApiResponse?.result?.heyelan,
                        earthquakerisk: riskApiResponse?.result?.deprem
                    })

                    if (resultRisk.landsliderisk > 5 || resultRisk.floodrisk > 5) {
                        props.store.setMessage("Sorguladığınız enlem boylam için risk bilgisi bulunamadı", true, "error")
                    } else {
                        props.store.setSelectedRisk(resultRisk)
                    }


                    if (props.queryGeojsonRef.current !== null) {
                        props.mapRef.current.removeLayer(props.queryGeojsonRef.current)
                    }

                    let geojson = {
                        "type": "Feature",
                        "properties": {
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [lng, lat]
                        }
                    };

                    props.queryGeojsonRef.current = L.geoJSON(geojson, { style: style }).addTo(props.mapRef.current);
                    props.mapRef.current.fitBounds(props.queryGeojsonRef.current.getBounds(), { maxZoom: 15 });
                    //props.mapRef.current.
                }
                props.store.setLoadingBarStatus(true)
            }
        ).catch(e => {
            console.log("e: ", e)
            if (e.response.status === 404) {
                props.store.setMessage("Sorguladığınız enlem boylam için risk bilgisi bulunamadı", true, "error")

            } else if (e.response.status === 401) {
                //props.store.setUserLogout()
                //props.store.setMapAuthorizedOverlayServiceList([])
                //props.store.setOverlayServiceList([])
                //navigate("/ingrit/kullanici-girisi");
            }
            props.store.setLoadingBarStatus(true)
        })
    }

    const onClickGotoRaporPage = () => {
        navigate("/rapor")
    }

    return (
        <Slide direction="right" in={props.store.mapLatLngMenuDisplay} mountOnEnter >
            <Paper className="mps-left-panel" style={{ borderRadius: "0 !important", width: "300px", height: "100vh" }} elevation={0}>
                <Card style={{ height: "100vh" }}>
                    <CardHeader subheader={<Typography variant="body1">
                        Enlem Boylam Bilgisi ile Risk Sorgulama Menüsü
                    </Typography>} action={
                        <IconButton aria-label="settings" onClick={onClickCloseEnlemBoylamSorgulamaMenu}>
                            <WestTwoToneIcon />
                        </IconButton>
                    }></CardHeader>

                    <div className='container'>
                        <div className='row' style={{ padding: "20px" }}>
                            <TextField label="Enlem: " variant='outlined' value={lat}
                                onChange={(event) => setLat(event.target.value)}></TextField>

                            <TextField label="Boylam: " variant='outlined' value={lng} style={{ marginTop: "15px" }}
                                onChange={(event) => setLng(event.target.value)}></TextField>

                            <Button variant='contained' style={{ width: "150px", marginTop: "10px", marginLeft: "auto" }}
                                onClick={onClickLatLngSorgula}>
                                Risk Sorgula
                            </Button>
                        </div>
                        <div className="row" style={{ display: (props.store.selectedRisk[0]?.latitude === 0) ? "none" : "block", padding: "20px" }}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography variant='body1' style={{fontWeight: "bold", marginBottom: "15px"}}>
                                        Risk Sorgulama Sonuçları
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2"><b>Enlem:</b> {props.store.selectedRisk[0]?.latitude} <b>(Derece)</b></Typography>
                                    <Typography variant="body2"><b>Boylam:</b> {props.store.selectedRisk[0]?.longitude} <b>(Derece)</b></Typography>
                                </Grid>
                                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                </Grid>
                                <Grid item xs={4} style={{textAlign: "center"}}>
                                    <Avatar sx={{
                                        width: 75, height: 75,
                                        bgcolor: (props.store.selectedRisk[0]?.landsliderisk === 5) ? "#ff0100" :
                                            (props.store.selectedRisk[0]?.landsliderisk === 4) ? "#ff5400" :
                                                (props.store.selectedRisk[0]?.landsliderisk === 3) ? "#ff7f01" :
                                                    (props.store.selectedRisk[0]?.landsliderisk === 2) ? "#ffea00" :
                                                        (props.store.selectedRisk[0]?.landsliderisk === 1) ? "#00e03c" : "",
                                        border: (props.store.selectedRisk[0]?.landsliderisk === 5) ? "3px solid #CB0909" :
                                            (props.store.selectedRisk[0]?.landsliderisk === 4) ? "3px solid #CB4902" :
                                                (props.store.selectedRisk[0]?.landsliderisk === 3) ? "3px solid #ff7f01" :
                                                    (props.store.selectedRisk[0]?.landsliderisk === 2) ? "3px solid #b4a605" :
                                                        (props.store.selectedRisk[0]?.landsliderisk === 1) ? "3px solid  #039229" : ""
                                    }} style={{ color: "#000" }}>
                                        {props.store.selectedRisk[0]?.landsliderisk}
                                    </Avatar>
                                    <Typography variant="body2" style={{ fontWeight: "bold", color: "#000" }}>Heyelan</Typography>
                                </Grid>

                                <Grid item xs={4} style={{textAlign: "center"}}>
                                    <Avatar sx={{
                                        width: 75, height: 75,
                                        bgcolor: (props.store.selectedRisk[0]?.floodrisk === 5) ? "#ff0100" :
                                            (props.store.selectedRisk[0]?.floodrisk === 4) ? "#ff5400" :
                                                (props.store.selectedRisk[0]?.floodrisk === 3) ? "#ff7f01" :
                                                    (props.store.selectedRisk[0]?.floodrisk === 2) ? "#ffea00" :
                                                        (props.store.selectedRisk[0]?.floodrisk === 1) ? "#00e03c" : "",
                                        border: (props.store.selectedRisk[0]?.floodrisk === 5) ? "3px solid #CB0909" :
                                            (props.store.selectedRisk[0]?.floodrisk === 4) ? "3px solid #CB4902" :
                                                (props.store.selectedRisk[0]?.floodrisk === 3) ? "3px solid #cf6701" :
                                                    (props.store.selectedRisk[0]?.floodrisk === 2) ? "3px solid #b4a605" :
                                                        (props.store.selectedRisk[0]?.floodrisk === 1) ? "3px solid  #039229" : ""
                                    }} style={{color: "#000" }}>
                                        {props.store.selectedRisk[0]?.floodrisk}
                                    </Avatar>
                                    <Typography variant="body2" style={{ fontWeight: "bold", color: "#000" }}>Taşkın</Typography>
                                </Grid>

                                <Grid item xs={4} style={{textAlign: "center"}}>
                                    <Avatar sx={{
                                        width: 75, height: 75,
                                        bgcolor: (props.store.selectedRisk[0]?.earthquakerisk === 5) ? "#ff0100" :
                                            (props.store.selectedRisk[0]?.earthquakerisk === 4) ? "#ff5400" :
                                                (props.store.selectedRisk[0]?.earthquakerisk === 3) ? "#ff7f01" :
                                                    (props.store.selectedRisk[0]?.earthquakerisk === 2) ? "#ffea00" :
                                                        (props.store.selectedRisk[0]?.earthquakerisk === 1) ? "#00e03c" : "",
                                        border: (props.store.selectedRisk[0]?.earthquakerisk === 5) ? "3px solid #CB0909" :
                                            (props.store.selectedRisk[0]?.earthquakerisk === 4) ? "3px solid #CB4902" :
                                                (props.store.selectedRisk[0]?.earthquakerisk === 3) ? "3px solid #cf6701" :
                                                    (props.store.selectedRisk[0]?.earthquakerisk === 2) ? "3px solid #b4a605" :
                                                        (props.store.selectedRisk[0]?.earthquakerisk === 1) ? "3px solid  #039229" : ""
                                    }} style={{ color: "#000" }}>
                                        {props.store.selectedRisk[0]?.earthquakerisk}
                                    </Avatar>
                                    <Typography variant="body2" style={{ fontWeight: "bold", color: "#000"}}>Deprem</Typography>
                                </Grid>
                            </Grid>
                            <Button variant="contained" color="primary" style={{ float: "right", width: "150px", marginTop: "15px", marginRight: "15px" }}
                                onClick={onClickGotoRaporPage}>
                                RİSK RAPORU
                            </Button>
                        </div>
                    </div>
                </Card>
            </Paper>
        </Slide>
    )
}))