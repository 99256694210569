import React, { useState } from 'react';
import { observer, inject } from "mobx-react"
import { toJS } from 'mobx';

import L from 'leaflet';
import 'leaflet-plugins/layer/tile/Yandex'

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';

import LayersIcon from '@mui/icons-material/Layers';
import MapIcon from '@mui/icons-material/Map';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import WestTwoToneIcon from '@mui/icons-material/WestTwoTone';

import DraggableList from './DraggableList/DraggableList';
import { Paper, Slide } from '@mui/material';

export const MapLayerListMenu = inject("store")(observer((props) => {

    /**
   *  katman listesi panelinde baselayer, overlay layer listesini gösterme durumu
   *  {mapLayerDisplay} 0 ise baseLayer listesi, 1 ise overlay listesi gösterilir
   */
    const [mapLayerDisplay, setMapLayerDisplay] = useState(0)
    /**
     * baseLayersDisplay true ise props.store.baseLayerList kayıtlı baselayer katman listesi gösterilir.
     */
    const [baseLayersDisplay, setBaseLayersDisplay] = useState(false)
    /**
     * overlayLayersDisplay true ise props.store.overlayLayerList kayıtlı overlayLayer katman listesi gösterilir.
     */
    const [overlayLayersDisplay, setOverlayLayersDisplay] = useState(true)

    /**
   * harita baseLayer bilgisinin tutulduğu state
   */
    const [selectedBaseLayer, setSelectedBaseLayer] = useState((props.store.darkMode) ? props.store.baseLayerList[2] : props.store.baseLayerList[5])

    const [bingHybrid, setBingHybrid] = useState(null);
    const [bingSatellite, setBingSatellite] = useState(null);
    const [bingRoad, setBingRoad] = useState(null);
    const [yandexHybrid, setYandexHybrid] = useState(null);
    const [yandexSatellite, setYandexSatellite] = useState(null);
    const [yandexRoad, setYandexRoad] = useState(null)

    /**
     * Base layer ve overlay layer listesi tab paneli değerine göre mapLayerDisplay, baseLayerDisplay, overlayLayerDisplay değerini değiştirir.
     * Layer tab panelinde geçiş işlemini sağlar
     * @param {*} event 
     * @param {*} newValue layer tab paneli aktif tab değeri
     */
    const onChangeLayerType = (event, newValue) => {
        setMapLayerDisplay(newValue)
        if (newValue === 0) {
            setBaseLayersDisplay(false)
            setOverlayLayersDisplay(true)
        } else {
            setBaseLayersDisplay(true)
            setOverlayLayersDisplay(false)
        }
    }

    /**
     * haritadaki baseLayer değişimini gerçekleştirir.
     * @param {*} inputLayer 
     */
    const onToggleLayer = (inputLayer) => {
        setSelectedBaseLayer(inputLayer)
        let layer = null
        if (inputLayer !== null && inputLayer !== "") {

            if (inputLayer.provider !== "Microsoft" && inputLayer.provider !== "Yandex") {

                if (yandexHybrid !== null) {
                    props.mapRef.current.removeLayer(yandexHybrid)
                }
                if (yandexRoad !== null) {
                    props.mapRef.current.removeLayer(yandexRoad)
                }
                if (yandexSatellite !== null) {
                    props.mapRef.current.removeLayer(yandexSatellite)
                }
                if (bingRoad !== null) {
                    props.mapRef.current.removeLayer(bingRoad)
                }
                if (bingSatellite !== null) {
                    props.mapRef.current.removeLayer(bingSatellite)
                }

                let url = inputLayer.url
                if (inputLayer.access_token !== "") {
                    url = url + inputLayer.access_token
                }

                layer = L.tileLayer(url);
                layer.addTo(props.mapRef.current);

            } else {
                if (inputLayer.provider === "Microsoft") {

                    /* props.mapRef.current.eachLayer(function (layer) {
                         if (layer instanceof L.TileLayer) {
                             let layerUrl = layer._url
                             if(! layerUrl.includes("reazy")){
                                 props.mapRef.current.removeLayer(layer);
                             }
                            
                         }
                     })*/

                    var bingDefaults = {
                        key: inputLayer.access_token,
                        detectRetina: false
                    };

                    if (inputLayer.url === "hybrid") {

                        if (bingHybrid === null) {
                            let bingMapsHydbridLayer = L.bingLayer(L.extend({}, bingDefaults, {
                                imagerySet: 'AerialWithLabels',
                                retinaDpi: false
                            }))
                            setBingHybrid(bingMapsHydbridLayer)
                            bingMapsHydbridLayer.addTo(props.mapRef.current);
                        } else {
                            bingHybrid.addTo(props.mapRef.current);
                        }

                        if (yandexHybrid !== null) {
                            props.mapRef.current.removeLayer(yandexHybrid)
                        }
                        if (yandexRoad !== null) {
                            props.mapRef.current.removeLayer(yandexRoad)
                        }
                        if (yandexSatellite !== null) {
                            props.mapRef.current.removeLayer(yandexSatellite)
                        }
                        if (bingRoad !== null) {
                            props.mapRef.current.removeLayer(bingRoad)
                        }
                        if (bingSatellite !== null) {
                            props.mapRef.current.removeLayer(bingSatellite)
                        }
                    } else if (inputLayer.url === "road") {
                        if (bingRoad === null) {
                            let bingMapsRoadLayer = L.bingLayer(L.extend({}, bingDefaults, {
                                imagerySet: 'RoadOnDemand',
                                retinaDpi: false
                            }))
                            setBingRoad(bingMapsRoadLayer)
                            bingMapsRoadLayer.addTo(props.mapRef.current);
                        } else {
                            bingRoad.addTo(props.mapRef.current);
                        }

                        if (yandexHybrid !== null) {
                            props.mapRef.current.removeLayer(yandexHybrid)
                        }
                        if (yandexRoad !== null) {
                            props.mapRef.current.removeLayer(yandexRoad)
                        }
                        if (yandexSatellite !== null) {
                            props.mapRef.current.removeLayer(yandexSatellite)
                        }
                        if (bingSatellite !== null) {
                            props.mapRef.current.removeLayer(bingSatellite)
                        }
                        if (bingHybrid !== null) {
                            props.mapRef.current.removeLayer(bingHybrid)
                        }
                    } else if (inputLayer.url === "satellite") {
                        if (bingSatellite === null) {
                            let bingMapsSatelliteLayer = L.bingLayer(L.extend({}, bingDefaults, {
                                imagerySet: 'Aerial',
                                retinaDpi: false
                            }))
                            setBingSatellite(bingMapsSatelliteLayer)
                            bingMapsSatelliteLayer.addTo(props.mapRef.current);
                        } else {
                            bingSatellite.addTo(props.mapRef.current);
                        }
                        if (yandexHybrid !== null) {
                            props.mapRef.current.removeLayer(yandexHybrid)
                        }
                        if (yandexRoad !== null) {
                            props.mapRef.current.removeLayer(yandexRoad)
                        }
                        if (yandexSatellite !== null) {
                            props.mapRef.current.removeLayer(yandexSatellite)
                        }
                        if (bingHybrid !== null) {
                            props.mapRef.current.removeLayer(bingHybrid)
                        }
                        if (bingRoad !== null) {
                            props.mapRef.current.removeLayer(bingRoad)
                        }
                    }
                } else {

                    /*props.mapRef.current.eachLayer(function (layer) {
                        if (layer instanceof L.TileLayer) {
                            let layerUrl = layer._url
                            if(! layerUrl.includes("reazy")){
                                props.mapRef.current.removeLayer(layer);
                            }
                           
                        }
                    }) */

                    if (inputLayer.url === "hybrid") {
                        let yandexHybridLayer = L.yandex('hybrid').addTo(props.mapRef.current)
                        setYandexHybrid(yandexHybridLayer)

                        if (bingHybrid !== null) {
                            props.mapRef.current.removeLayer(bingHybrid)
                        }
                        if (bingRoad !== null) {
                            props.mapRef.current.removeLayer(bingRoad)
                        }
                        if (bingSatellite !== null) {
                            props.mapRef.current.removeLayer(bingSatellite)
                        }
                        if (yandexRoad !== null) {
                            props.mapRef.current.removeLayer(yandexRoad)
                        }
                        if (yandexSatellite !== null) {
                            props.mapRef.current.removeLayer(yandexSatellite)
                        }

                    } else if (inputLayer.url === "road") {
                        let yandexRoadLayer = L.yandex().addTo(props.mapRef.current)
                        setYandexRoad(yandexRoadLayer)

                        if (bingHybrid !== null) {
                            props.mapRef.current.removeLayer(bingHybrid)
                        }
                        if (bingRoad !== null) {
                            props.mapRef.current.removeLayer(bingRoad)
                        }
                        if (bingSatellite !== null) {
                            props.mapRef.current.removeLayer(bingSatellite)
                        }
                        if (yandexSatellite !== null) {
                            props.mapRef.current.removeLayer(yandexSatellite)
                        }
                        if (yandexHybrid !== null) {
                            props.mapRef.current.removeLayer(yandexHybrid)
                        }
                    } else if (inputLayer.url === "satellite") {
                        let yandexSatelliteLayer = L.yandex({ type: 'satellite' }).addTo(props.mapRef.current)
                        setYandexSatellite(yandexSatelliteLayer)

                        if (bingHybrid !== null) {
                            props.mapRef.current.removeLayer(bingHybrid)
                        }
                        if (bingRoad !== null) {
                            props.mapRef.current.removeLayer(bingRoad)
                        }
                        if (bingSatellite !== null) {
                            props.mapRef.current.removeLayer(bingSatellite)
                        }
                        if (yandexHybrid !== null) {
                            props.mapRef.current.removeLayer(yandexHybrid)
                        }
                        if (yandexRoad !== null) {
                            props.mapRef.current.removeLayer(yandexRoad)
                        }
                    }
                }
            }

            for (let index = 0; index < props.store.mapAuthorizedOverlayServiceList.length; index++) {
                const inputOverlayLayer = props.store.mapAuthorizedOverlayServiceList[index];
                if (inputOverlayLayer.active === 1) {
                    window[inputOverlayLayer.key] = L.tileLayer(inputOverlayLayer.url,
                        { foo: 'license', attribution: '&copy; <a href="https://reazy.co">Reazy.co</a> contributors' });

                    //console.log("inputOverlayLayer.key: ", inputOverlayLayer.key)
                    // window[inputOverlayLayer.key] = source.getLayer(inputOverlayLayer.key)
                    props.mapRef.current.getPane('pane-map-' + inputOverlayLayer.key).style.zIndex = inputOverlayLayer.zIndex + 500;

                    props.mapRef.current.addLayer(window[inputOverlayLayer.key])

                    window[inputOverlayLayer.key].on('loading', function (event) {
                        props.store.setLoadingBarStatus(false)
                    });

                    window[inputOverlayLayer.key].on('load', function (event) {
                        props.store.setLoadingBarStatus(true)
                    });
                }
            }
        }
    }

    const onClickCloseBaseLayerMenu = () => {
        props.store.setMapLayerPanel(false)
    }

    const onDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination) return;
        const result = Array.from(props.store.mapAuthorizedOverlayServiceList);
        const [removed] = result.splice(source.index, 1);
        result.splice(destination.index, 0, removed);

        let overlayList = []
        for (let index = 0; index < result.length; index++) {
            const tempAuthOverlay = toJS(result[index]);
            tempAuthOverlay.zIndex = 100 - index
            overlayList.push(tempAuthOverlay)
        }
        props.store.setMapAuthorizedOverlayServiceList(overlayList)

    };

    return (
        <Slide direction="right" in={props.store.mapLayerPanel} mountOnEnter >
            <Paper className="mps-left-panel" style={{ borderRadius: "0 !important", width: "300px", height: "100vh" }} elevation={0}>
                <Card style={{ height: "100vh" }}>
                    <CardHeader subheader={<Typography variant="body1">
                        Katman Menüsü
                    </Typography>} action={
                        <IconButton aria-label="settings" onClick={onClickCloseBaseLayerMenu}>
                            <WestTwoToneIcon />
                        </IconButton>
                    }></CardHeader>
                    <BottomNavigation
                        value={mapLayerDisplay}
                        onChange={(event, newValue) => onChangeLayerType(event, newValue)}
                        showLabels>
                        <BottomNavigationAction label="Katmanlar" icon={<LayersIcon />} style={{ paddingBottom: "16px" }} />
                        <BottomNavigationAction label="Altlık Haritalar" icon={<MapIcon />} style={{ paddingBottom: "16px" }} />
                    </BottomNavigation>
                    <div className="mps-layer-container mps-layer-overflow-auto" style={{ display: (overlayLayersDisplay) ? "block" : "none" }}>
                        <div className="row" style={{ margin: "0", padding: "0px", paddingTop: "10px", paddingBottom: "50px" }}>
                            <DraggableList items={props.store.mapAuthorizedOverlayServiceList} onDragEnd={onDragEnd} mapRef={props.mapRef} />
                        </div>
                    </div>

                    <div className="mps-layer-container mps-layer-overflow-auto" style={{ display: (baseLayersDisplay) ? "block" : "none" }}>
                        <div className="row" style={{ margin: "0", padding: "15px", paddingTop: "20px" }}>
                            {props.store.baseLayerList.map((item, idx) => (
                                <div key={idx} className="col-6" style={{ paddingLeft: "0", paddingRight: "10px" }}>
                                    <Card style={{ marginBottom: "15px", boxShadow: "none", backgroundColor: "rgba(0,0,0, 0.05)" }}>
                                        <CardMedia style={{ height: "100px" }}
                                            image={ item.image}
                                            title={item.name} />
                                        <CardContent style={{ padding: "5px" }}>
                                            <Grid container spacing={1} style={{ minHeight: "85px" }}>
                                                <Grid item xs={7}>
                                                    <Typography component="h5" variant="body2">
                                                        <b>{item.provider}</b> <br /> {item.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <IconButton aria-label="play/pause" onClick={() => onToggleLayer(item)}
                                                        style={{ float: "right" }}>
                                                        {(selectedBaseLayer.id === item.id) ? <DoneIcon /> : <AddIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </div>
                            ))
                            }
                        </div>
                    </div>

                </Card>
            </Paper>
        </Slide>
    )

}))