import { types } from 'mobx-state-tree'
import { toJS } from 'mobx'

import { User } from '../apis/model/mapisso/User'

import { BaseLayer } from '../mapviewer/model/BaseLayer'
import { OverlayService } from '../apis/model/arcgisserver/OverlayService'
import { EditingLayers } from '../mapviewer/model/EditingLayers'
import { Layer } from '../apis/model/arcgisserver/Layer'
import { Risk } from '../apis/model/ingrit/Risk'

export const MapissoStore = types
    .model("MapissoStore", {
        baseUrl: types.string,
        arcgisServerDomain: types.string,
        isLogin: types.boolean,
        darkMode: types.boolean,
        token: types.string,
        apikey: types.string,
        messageInfo: types.string,
        messageDisplay: types.boolean,
        messageType: types.string,
        paginationMenuDisplay: types.boolean,
        introductionMenu: types.boolean,
        onBoardingShow: types.boolean,
        loadingBarStatus: types.boolean,
        user: types.optional(types.array(User), []),
        userList: types.optional(types.array(User), []),

        //map viewer v2 
        mapCenterLat: types.number,
        mapCenterLng: types.number,
        mapZoom: types.number,
        baseLayerList: types.optional(types.array(BaseLayer), []),
        overlayServiceList: types.optional(types.array(OverlayService), []),
        mapPaginationMenuDisplay: types.optional(types.boolean, false),
        mapQueryMenuDisplay: types.optional(types.boolean, false),
        mapEditMenuDisplay: types.optional(types.boolean, false),
        mapLayerPanel: types.optional(types.boolean, false),
        mapEditingLayersList: types.optional(types.array(EditingLayers), []),
        mapActiveEditMode: types.optional(types.string, ""),
        mapSelectedFeature: types.optional(types.string, ""),
        mapGetFeatureInfoButtonStatus: types.optional(types.boolean, false),
        mapGetFeatureInfoSelectedLayer: types.optional(types.array(OverlayService), []),
        mapGetFeatureInfoSelectedServiceLayer: types.optional(types.array(Layer), []),
        mapAttributeTableDisplay: types.optional(types.boolean, false),
        mapAuthorizedOverlayServiceList: types.optional(types.array(OverlayService), []),
        mapAuthorizedQueryableServiceList: types.optional(types.array(OverlayService), []),
        mapSelectByAttributesDisplay: types.optional(types.boolean, false),
        mapSelectByAttributeResultDisplay: types.optional(types.boolean, false),
        mapFilterMenuGeomEditStatus: types.optional(types.boolean, false),
        mapAppsMenuDisplay: types.optional(types.boolean, false),

        selectedRisk: types.optional(types.array(Risk), []),
        mapGeocoderMenuDisplay: types.optional(types.boolean, false),
        mapLatLngMenuDisplay: types.optional(types.boolean, false),
        mapUserMenuDisplay: types.optional(types.boolean, false),

    }).actions(self => ({
        setUserLogged() {
            self.isLogin = true
        },
        setUserLogout() {
            self.isLogin = false
            self.token = ""
        },
        setDarkLightMode(inputMode) {
            self.darkMode = inputMode
        },
        setToken: (function (token) {
            self.token = token
        }),
        setApiKey: (function (apikey) {
            self.apikey = apikey
        }),
        setMessage: (function (message, display, messageType) {
            self.messageInfo = message
            self.messageDisplay = display
            self.messageType = (messageType === undefined) ? "info" : messageType
        }),
        setPaginationMenuDisplay: (function () {
            self.paginationMenuDisplay = !self.paginationMenuDisplay
        }),
        setIntroductionMode(display) {
            self.introductionMenu = display
        },
        setOnBoardingShow(display) {
            self.onBoardingShow = display
        },
        setLoadingBarStatus(loadingBar) {
            self.loadingBarStatus = loadingBar
        },
        setUser: (function (user) {
            self.user = []
            self.user.push(user)
        }),
        setUserList: (function (userList) {
            self.userList = []
            for (let i = 0; i < userList.length; i++) {
                self.userList.push(userList[i])
            }
        }),
        setDarkMode: (function () {
            self.darkMode = !self.darkMode
        }),

        /**
         * map viewer methods started
         */

        setMapCenterLat: (function (inputCenterLat) {
            self.mapCenterLat = inputCenterLat
        }),
        setMapCenterLng: (function (inputCenterLng) {
            self.mapCenterLng = inputCenterLng
        }),
        setMapZoom: (function (inputZoom) {
            self.mapZoom = inputZoom
        }),
        setBaseLayerList: (function (layer) {
            self.baseLayerList = []
            self.baseLayerList.push(layer)
        }),
        addOverlayServiceList: (function (inputOverlayLayer) {
            self.overlayServiceList.push(inputOverlayLayer)
        }),
        setOverlayServiceList: (function (inputOverlayServiceList) {
            self.overlayServiceList = []
            for (let i = 0; i < inputOverlayServiceList.length; i++) {
                self.overlayServiceList.push(inputOverlayServiceList[i])
            }
        }),
        setOverlayServiceLayer: (function (serviceArrayIndex, layerList) {
            self.overlayServiceList[serviceArrayIndex].layers = layerList
        }),
        setOverlayServiceLegends: (function (serviceArrayIndex, legendList) {
            let overlayServiceList = toJS(self.overlayServiceList)
            for (let i = 0; i < overlayServiceList.length; i++) {
                if( overlayServiceList[i].id === serviceArrayIndex){
                    overlayServiceList[i].legends = legendList
                }
            }
            self.overlayServiceList = []
            self.overlayServiceList =overlayServiceList
        }),
        setMapPaginationMenuDisplay: (function (inputDisplayValue) {
            self.mapPaginationMenuDisplay = inputDisplayValue
        }),
        setMapQueryMenuDisplay: (function (inputDisplayValue) {
            self.mapQueryMenuDisplay = inputDisplayValue
        }),
        setMapEditMenuDisplay: (function (inputDisplayValue) {
            self.mapEditMenuDisplay = inputDisplayValue
        }),
        setMapLayerPanel(display) {
            self.mapLayerPanel = display
        },
        addEditingLayersList: (function (inputEditingLayer) {
            let isExist = false
            for (let i = 0; i < self.mapEditingLayersList.length; i++) {
                const tempEditingLayer = self.mapEditingLayersList[i];
                if (tempEditingLayer.layerId === inputEditingLayer.layerId) {
                    isExist = true
                }
            }
            if (!isExist) {
                self.mapEditingLayersList.push(inputEditingLayer)
            }
        }),
        updateEditingLayers: (function (inputEditLayerList) {
            self.mapEditingLayersList = []
            for (let index = 0; index < inputEditLayerList.length; index++) {
                self.mapEditingLayersList.push(inputEditLayerList[index]);
            }
        }),
        deleteEditingLayer: (function (deletedLayerIndex) {
            let tempEditingLayerList = toJS(self.mapEditingLayersList)
            tempEditingLayerList.splice(deletedLayerIndex, 1)
            self.mapEditingLayersList = tempEditingLayerList
        }),
       
        setMapActiveEditMode: (function (inputEditMode) {
            self.mapActiveEditMode = inputEditMode
        }),
        setMapSelectedFeature(mapSelectedFeature) {
            self.mapSelectedFeature = mapSelectedFeature
        },
        setMapGetFeatureInfoButtonStatus: (function (status) {
            self.mapGetFeatureInfoButtonStatus = status
        }),
        setMapGetFeatureInfoSelectedLayer: (function (selectedGetInfoLayer) {
            self.mapGetFeatureInfoSelectedLayer = []
            if (selectedGetInfoLayer !== null) {
                self.mapGetFeatureInfoSelectedLayer.push(selectedGetInfoLayer)
            }
        }),
        
        setMapAuthorizedQueryableServiceList: function(inputOverlayServiceList){
            self.mapAuthorizedQueryableServiceList = inputOverlayServiceList
        },
        setMapAuthorizedOverlayServiceList: function(inputOverlayServiceList){
            self.mapAuthorizedOverlayServiceList = inputOverlayServiceList
        },
        setMapAuthorizedOverlayOpacity: (function (serviceId, opacity) {
            let overlayServiceList = self.mapAuthorizedOverlayServiceList
            let updatedOverlayServiceList = []
            for (let i = 0; i < overlayServiceList.length; i++) {
                let tempOverlay = toJS(overlayServiceList[i])
                if( overlayServiceList[i].id === serviceId){
                    tempOverlay.opacity = opacity
                }
                updatedOverlayServiceList.push(tempOverlay)
            }
            self.mapAuthorizedOverlayServiceList = updatedOverlayServiceList
        }),
        setMapAuthorizedOverlayServiceLegends: (function (serviceId, legendList) {
            let overlayServiceList = toJS(self.mapAuthorizedOverlayServiceList)
            let updatedOverlayServiceList = []
            for (let i = 0; i < overlayServiceList.length; i++) {
                let tempOverlay = overlayServiceList[i]
                if( overlayServiceList[i].id === serviceId){
                    tempOverlay.legends = toJS(legendList)
                }
                updatedOverlayServiceList.push(tempOverlay)
            }
            self.mapAuthorizedOverlayServiceList = updatedOverlayServiceList
        }),
        setMapSelectByAttributesDisplay: (function (display) {
            self.mapSelectByAttributesDisplay = display
        }),
        setMapGetFeatureInfoSelectedServiceLayer: (function (selectedLayer){
            self.mapGetFeatureInfoSelectedServiceLayer = []
            if(selectedLayer !== null){
                self.mapGetFeatureInfoSelectedServiceLayer.push(selectedLayer)
            }
        }),
        setMapSelectByAttributeResultDisplay: (function (display) {
            self.mapSelectByAttributeResultDisplay = display
        }),
        setMapFilterMenuGeomEditStatus: (function (display) {
            self.mapFilterMenuGeomEditStatus = display
        }),
        setMapAppsMenuDisplay: (function (display) {
            self.mapAppsMenuDisplay = display
        }),
        setMapGetFeatureInfoDisplay() {
            self.mapGetFeatureInfoDisplay = !self.mapGetFeatureInfoDisplay
        },
        setMapGetFeatureInfoClose() {
            self.mapGetFeatureInfoDisplay = false
        },
        setMapGetFeatureInfoFalse() {
            self.mapGetFeatureInfoDisplay = false
        },
        setAttributeTableDisplay(inputDisplay){
            self.mapAttributeTableDisplay = inputDisplay
        },
         //map viewer methods finished

        setSelectedRisk: (function (inputRiskData){
            self.selectedRisk = []
            if(inputRiskData !== null){
                self.selectedRisk.push(inputRiskData)
            }
        }),
        setMapGeocoderMenuDisplay(inputDisplay){
            self.mapGeocoderMenuDisplay = inputDisplay
        }, 
        setMapLatLngMenuDisplay(inputDisplay){
            self.mapLatLngMenuDisplay = inputDisplay
        },
        setMapUserMenuDisplay(inputDisplay){
            self.mapUserMenuDisplay = inputDisplay
        }
       
    }))