import { geojsonToArcGIS } from '@esri/arcgis-to-geojson-utils';
const gjv = require("geojson-validation");

function flipGeojsonCoordinates (coordinateArray) {
  let flippedArray = []
  for (let i = 0; i < coordinateArray.length; i++) {
      let coordinate = coordinateArray[i]
            
      let flippedCoordinate = [coordinate[1], coordinate[0]]
      
      flippedArray.push(flippedCoordinate)
  }
 
  return flippedArray;
}

function geojsonToEsriGeom(geojson) {
  let esriGeom = null
  if (gjv.valid(geojson)) {
    //console.log("this is valid GeoJSON!", geojson);
    esriGeom = geojsonToArcGIS(geojson);
  } else {
    //console.log("geojson file is  not valid")
  }
  return esriGeom;
}

function createWhereClauseForArcgisServerAPI(filterArray, filterType) {

  // Initialize an empty array to store individual where clauses
  const whereClauses = [];

  // Iterate through the filter objects and create where clauses
  filterArray.forEach(filter => {
    const { column, operator, value } = filter;

    // Construct the where clause for the current filter
    let whereClause = `${column} ${operator} `;

    // Check the data type of the value and add quotes if it's a string
    if (typeof value === "string") {
      whereClause += `'${value}'`;
    } else {
      whereClause += value;
    }

    whereClauses.push(whereClause);
  });

  // Join the individual where clauses using "AND" or "OR" as needed
  const finalWhereClause = whereClauses.join(" " + filterType + " "); // You can use "OR" if needed

  // The final where clause to use in your ArcGIS Server REST API request
  console.log(finalWhereClause);

  return finalWhereClause;
}

// eslint-disable-next-line
export default {flipGeojsonCoordinates, geojsonToEsriGeom, createWhereClauseForArcgisServerAPI};