import React, { useState, useEffect } from 'react'
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';

import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MapissoAlert from '../components/common/MapissoAlert'

import config from '../config/client.json'

import { UserAPI } from '../apis/UserAPI';

const LoginPage = inject("store")(observer((props) => {

    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    useEffect(() => {
        if (!props.store.isLogin) {
            navigate("/kullanici-girisi")
        } else {
            navigate("/harita")
        }
        // eslint-disable-next-line
    }, [])

    const onLogin = async () => {
        props.store.setLoadingBarStatus(false)
        if (email !== "" && password !== "") {

            let response = await UserAPI.login(props, email, password);
            if (response.result !== undefined) {
                if (response.status === 200) {
                    props.store.setLoadingBarStatus(true)
                    props.store.setUserLogged()
                    props.store.setToken(response.result.token)
                    props.store.setApiKey(response.result.apikey)
                    props.store.setUser(response.result)

                    let userAPIResponse = await UserAPI.getUserList(props, false)
                    if (userAPIResponse.status === 200) {
                        props.store.setUserList(userAPIResponse.result)
                    }

                    navigate("/harita")
                }
            } else {
                props.store.setMessage("Yetkisiz giriş denemesi", true, "error")
            }

        } else {
            props.store.setMessage("Giriş yapmak için kullanıcı bilgilerinizi eksiksiz giriniz.", true, "error")
        }
        props.store.setLoadingBarStatus(true)
    }

    const onEmailTextFieldChange = (event) => {
        setEmail(event.target.value)
    }

    const onPasswordTextFieldChange = (event) => {
        setPassword(event.target.value)
    }

    const onKeyPressHandler = (target) => {
        if (target.charCode === 13) {
            onLogin()
        }
    }

    return (
        <>
            <BrowserView>
                <div className="container mps-container">
                    <div className="row width100vh" style={{ margin: "0" }}>
                        <div className="col-md-4 height100vh" style={{ padding: "75px", maxWidth: "450px", minWidth: "450px", paddingTop: "30px" }}>

                            <Typography variant="h4" style={{ textAlign: "left", fontWeight: "bold", marginBottom: "50px" }}>{config.appName}
                                <span> - Web Tabanlı Harita Uygulaması</span></Typography>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "bold", marginTop: "50px" }}>Giriş Yap</Typography>

                            <p style={{ fontWeight: "bold", marginTop: "10px" }}>E-posta</p>
                            <TextField value={email} variant="filled" style={{ width: "100%" }}
                                onChange={onEmailTextFieldChange} onKeyPress={onKeyPressHandler} />
                            <p style={{ fontWeight: "bold", marginTop: "25px" }}>Şifre</p>
                            <TextField value={password} variant="filled" type="password"
                                onKeyPress={onKeyPressHandler} style={{ width: "100%", marginBottom: "10px" }}
                                onChange={onPasswordTextFieldChange} />
                            <p></p>
                            <Button variant="contained" color="primary" onClick={onLogin}
                                style={{ width: "100%", height: "50px", fontWeight: "600" }}>
                                GİRİŞ YAP
                            </Button>

                            <LinearProgress hidden={props.store.loadingBarStatus} color="secondary" />
                        </div>
                        <div className='col-md-8' style={{ padding: "20px", maxWidth: "calc(100% - 450px)", minWidth: "calc(100% - 450px)" }}>
                            <div className={(props.store.darkMode) ? "bg-image-darkmode" : "bg-image"}
                                style={{ width: "100%", paddingTop: "30px" }}>

                                <Typography variant="body1" style={{ marginLeft: "30px", marginTop: "10px", color: "#fff" }}>{config.appName}</Typography>
                                <Typography variant="body1" style={{ marginLeft: "30px", color: "#fff" }}>{config.version}</Typography>
                                <Typography variant="caption" style={{ marginLeft: "30px", color: "#fff" }}>© 2012-2023 Mapisso. Tüm Hakları Saklıdır</Typography>

                            </div>
                        </div>
                    </div>
                    <MapissoAlert />
                </div>
            </BrowserView>
            <MobileView>
                <div className="mps-container">
                    <div className="row" style={{ margin: "0px" }}>
                        <div className={(props.store.darkMode) ? "bg-image-darkmode-mobile"
                            : "bg-image-mobile"}
                            style={{ padding: "30px" }}>

                            <img src={process.env.PUBLIC_URL + "/assets/images/" + config.logo}
                                style={{ width: "90px", marginBottom: "10px" }} alt="login-background"></img>

                            <Typography variant="h6" style={{ textAlign: "left", fontWeight: "bold", marginBottom: "15px", color: "#fff" }}>{config.clientName}</Typography>
                            <Typography variant="h4" style={{ textAlign: "left", fontWeight: "bold", marginBottom: "50px", color: "#fff" }}>{config.appName}</Typography>
                            <Typography variant="h5" style={{ textAlign: "left", fontWeight: "bold", marginTop: "50px", color: "#fff" }}>Giriş Yap</Typography>

                            <p style={{ fontWeight: "bold", marginTop: "10px", color: "#fff" }}>E-posta</p>
                            <TextField value={email} variant="filled" style={{ width: "100%" }}
                                onChange={onEmailTextFieldChange} onKeyPress={onKeyPressHandler} />
                            <p style={{ fontWeight: "bold", marginTop: "25px", color: "#fff" }}>Şifre</p>
                            <TextField value={password} variant="filled" type="password"
                                onKeyPress={onKeyPressHandler} style={{ width: "100%", marginBottom: "10px" }}
                                onChange={onPasswordTextFieldChange} />
                            <p></p>
                            <Button variant="contained" color="primary" onClick={onLogin}
                                style={{ width: "100%", height: "50px", fontWeight: "600" }}>
                                GİRİŞ YAP
                            </Button>
                            <LinearProgress hidden={props.store.loadingBarStatus} color="secondary" />
                        </div>
                    </div>
                </div>
            </MobileView>
        </>

    )
}))

export default inject("store")(observer(LoginPage));