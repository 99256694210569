import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from "mobx-react";
import { onSnapshot } from 'mobx-state-tree'
import App from './App';

import { MapissoStore } from './store/MapissoStore'

let baseLayerArray = [
  {
    id: 1,
    name: "Temel Altlık Harita",
    provider: "MAPBOX",
    image: "/assets/images/layer/mapboxgray.png",
    url: "https://api.mapbox.com/styles/v1/muhammetemreyildirim/cl3mprs7t002j15ms12bkwxxa/tiles/256/{z}/{x}/{y}?access_token=",
    access_token: "pk.eyJ1IjoibXVoYW1tZXRlbXJleWlsZGlyaW0iLCJhIjoieFJRX3Y0dyJ9.1q-BXxW6oTiOONEWogfxmQ",
    tms: false
  },
  {
    id: 2,
    name: "Koyu",
    provider: "MAPBOX",
    image: "/assets/images/layer/mapbox-dark.png",
    url: "https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=",
    access_token: "pk.eyJ1IjoibXVoYW1tZXRlbXJleWlsZGlyaW0iLCJhIjoiY2tsNmt3MnFiMWV3ZDJxbW5ydTJicXpqbyJ9.d_ZD6p2qqG-3VgJZiB65kg",
    tms: false
  },
  {
    id: 3,
    name: "Uydu",
    provider: "MAPBOX",
    image: "/assets/images/layer/mapbox-satellite.png",
    url: "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg90?access_token=",
    access_token: "pk.eyJ1IjoibXVoYW1tZXRlbXJleWlsZGlyaW0iLCJhIjoiY2tsNmt3MnFiMWV3ZDJxbW5ydTJicXpqbyJ9.d_ZD6p2qqG-3VgJZiB65kg",
    tms: false
  },
  {
    id: 4,
    name: "Uydu",
    provider: "ESRI",
    image: "/assets/images/layer/esri-satellite.png",
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    access_token: "",
    tms: false
  },
  {
    id: 5,
    name: "Topografik",
    provider: "ESRI",
    image: "/assets/images/layer/esri-topografic.png",
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
    access_token: "",
    tms: false
  },
  {
    id: 6,
    name: "Street",
    provider: "ESRI",
    image: "/assets/images/layer/esri-street.png",
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
    access_token: "",
    tms: false
  },
  {
    id: 7,
    name: "Sokak",
    provider: "OSM",
    image: "/assets/images/layer/openstreetmap-sokak.png",
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    access_token: "",
    tms: false
  },
  {
    id: 8,
    name: "Bisiklet",
    provider: "OSM",
    image: "/assets/images/layer/thunderforest-cycle.png",
    url: "https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=",
    access_token: "e83bb847b1574d589a86bdd341da9249",
    tms: false
  },
  {
    id: 9,
    name: "Mapnik",
    provider: "OSM",
    image: "/assets/images/layer/mapnik.png",
    url: "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
    access_token: "",
    tms: false
  },
  {
    id: 10,
    name: "Sokak",
    provider: "MAPBOX",
    image: "/assets/images/layer/mapbox-street.png",
    url: "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}@2x?access_token=",
    access_token: "pk.eyJ1IjoibXVoYW1tZXRlbXJleWlsZGlyaW0iLCJhIjoiY2tsNmt3MnFiMWV3ZDJxbW5ydTJicXpqbyJ9.d_ZD6p2qqG-3VgJZiB65kg",
    tms: false
  }, {
    id: 11,
    name: "BingMaps Hybrid",
    provider: "Microsoft",
    url: "hybrid",
    image: "/assets/images/layer/binghybrid.png",
    access_token: "AjzokfoWA7K1BLC58cFl2Jvz2gntfkesatoEATuQZHmArW6mjqRzZ9o8dMolrfjH",
    tms: false
  }, {
    id: 12,
    name: "BingMaps Road",
    provider: "Microsoft",
    url: "road",
    image: "/assets/images/layer/bingmaps.png",
    access_token: "AjzokfoWA7K1BLC58cFl2Jvz2gntfkesatoEATuQZHmArW6mjqRzZ9o8dMolrfjH",
    tms: false
  }, {
    id: 13,
    name: "BingMaps Satellite",
    provider: "Microsoft",
    url: "satellite",
    image: "/assets/images/layer/binghybrid.png",
    access_token: "AjzokfoWA7K1BLC58cFl2Jvz2gntfkesatoEATuQZHmArW6mjqRzZ9o8dMolrfjH",
    tms: false
  }
]

let initialState = {
  //baseUrl: "http://localhost",
  baseUrl: "",
  //arcgisServerDomain: "https://cbs.manisasu.gov.tr/arcgis/",
  arcgisServerDomain: "https://cbs.rizeozelidare.gov.tr/server/",
  loadingBarStatus: true,
  darkMode: false,
  isLogin: false,
  mapLayerPanel: false,
  token: "",
  apikey: "",
  messageInfo: "",
  messageDisplay: false,
  messageType: "info",
  paginationMenuDisplay: true,
  mapParselSorgulamaPanel: false,
  mapHesabimPanel: false,
  mapAbonePanelDisplay: false,
  mapCenterLat: 39,
  mapCenterLng: 41,
  mapZoom: 2,
  baseLayerList: baseLayerArray,
  /*overlayServiceList: overlayServiceArray,*/
  onBoardingShow: true,
  introductionMenu: true
}

if (localStorage.getItem("mapisso.insurance.1.0.3")) {
  // console.log("localstorage if")
  // localStorage.clear("mapisso.insurance.1.0.3")     
  initialState = JSON.parse(localStorage.getItem("mapisso.insurance.1.0.3"))
}

let mapissoStore = MapissoStore.create(initialState)

onSnapshot(mapissoStore, snapshot => {
  localStorage.setItem("mapisso.insurance.1.0.3", JSON.stringify(snapshot))
})

function renderApp() {
  let root = ReactDOM.createRoot(document.getElementById("root"))

  root.render(
    <Provider store={mapissoStore}>
      <App />
    </Provider>);
}

renderApp()