import { api } from "./configs/AxiosConfigs"
import { defineCancelApiObject } from "./configs/AxiosUtils"
 
export const BasarsoftGeocoderAPI = {
    geocode: async function (props, q, cancel = false,) {
        props.store.setLoadingBarStatus(false)
        const response = await api.request({
            url: `https://bms.basarsoft.com.tr/Service/api/v1/Geocode2ext?accId=LOjF1Q-yJkW6bPfw0RV9nA&appCode=wsmx7KMkbEO6PL3ZemKCVQ&q=${q}&maxRecordCount=5&dbg=false`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.geocode.name].handleRequestCancellation().signal : undefined,
        })
        props.store.setLoadingBarStatus(true)
        return response.data
    }
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(BasarsoftGeocoderAPI) 