import React, { useEffect, useRef } from 'react'
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs'
import L from 'leaflet';
import "proj4";
import "proj4leaflet";
import 'leaflet-editable';
import 'leaflet-measure/dist/leaflet-measure.tr'
import 'leaflet-measure-path'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-plugins/layer/tile/Yandex'
import 'leaflet-plugins/layer/tile/Bing'
import 'leaflet-plugins/layer/tile/Bing.addon.applyMaxNativeZoom'

import { Grid, Typography, Avatar, Button } from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const RaporPage = inject("store")(observer((props) => {

    const navigate = useNavigate();

    const mapRefHeyelan = useRef(null);
    const mapRefTaskin = useRef(null);
    const mapRefDeprem = useRef(null);

    /** 
     * creating maps and base layer settings
     */
    useEffect(() => {

        let layerHeyelan = null
        let layerTaskin = null
        let layerDeprem = null

        let taskinUrl = props.store.baseLayerList[6].url
        if (props.store.baseLayerList[6].access_token !== "") {
            taskinUrl = taskinUrl + props.store.baseLayerList[6].access_token
        }
        layerTaskin = L.tileLayer(taskinUrl);

        mapRefTaskin.current = L.map("maptaskin", {
            editable: true,
            zoomControl: false,
            crs: L.CRS.EPSG3857,
            attributionControl: false,
            center: [39, 41],
            zoom: 5,
            layers: [
                layerTaskin
            ],
            maxZoom: 19
        });

        mapRefTaskin.current.dragging.disable();

        let url = props.store.baseLayerList[6].url
        if (props.store.baseLayerList[6].access_token !== "") {
            url = url + props.store.baseLayerList[6].access_token
        }
        layerHeyelan = L.tileLayer(url);

        mapRefHeyelan.current = L.map("mapheyelan", {
            editable: true,
            zoomControl: false,
            crs: L.CRS.EPSG3857,
            attributionControl: false,
            center: [39, 41],
            zoom: 5,
            layers: [
                layerHeyelan
            ],
            maxZoom: 19
        });
        mapRefHeyelan.current.dragging.disable();

        let depremUrl = props.store.baseLayerList[6].url
        if (props.store.baseLayerList[6].access_token !== "") {
            depremUrl = taskinUrl + props.store.baseLayerList[6].access_token
        }
        layerDeprem = L.tileLayer(depremUrl);

        mapRefDeprem.current = L.map("mapdeprem", {
            editable: true,
            zoomControl: false,
            crs: L.CRS.EPSG3857,
            attributionControl: false,
            center: [39, 41],
            zoom: 5,
            layers: [
                layerDeprem
            ],
            maxZoom: 19
        });
        mapRefDeprem.current.dragging.disable();
       
        let geojson = {
            "type": "Feature",
            "properties": {
            },
            "geometry": {
                "type": "Point",
                "coordinates": [props.store.selectedRisk[0]?.latitude, props.store.selectedRisk[0]?.longitude]
            }
        };

        let heyelanLayer = L.geoJSON(geojson).addTo(mapRefHeyelan.current);
        let taskinLayer = L.geoJSON(geojson).addTo(mapRefTaskin.current);
        let depremLayer = L.geoJSON(geojson).addTo(mapRefDeprem.current);

        mapRefHeyelan.current.fitBounds(heyelanLayer.getBounds(), { maxZoom: 10 });
        mapRefTaskin.current.fitBounds(taskinLayer.getBounds(), { maxZoom: 10 });
        mapRefDeprem.current.fitBounds(depremLayer.getBounds(), { maxZoom: 10 });

        window["rapor-heyelan-tile"] = L.tileLayer("https://tile.reazy.co/v1/heyelan/{z}/{x}/{y}.png?access_token=" + props.store.apikey,
            { foo: 'license', attribution: '&copy; <a href="https://reazy.co">Reazy.co</a> contributors', opacity: 0.5 });

        window["rapor-taskin-tile"] = L.tileLayer("https://tile.reazy.co/v1/sel/{z}/{x}/{y}.png?access_token=" + props.store.apikey,
        { foo: 'license', attribution: '&copy; <a href="https://reazy.co">Reazy.co</a> contributors', opacity: 0.5  });

        window["rapor-deprem-tile"] = L.tileLayer("https://tile.reazy.co/v1/deprem/{z}/{x}/{y}.png?access_token=" + props.store.apikey,
        { foo: 'license', attribution: '&copy; <a href="https://reazy.co">Reazy.co</a> contributors', opacity: 0.5  });

        mapRefTaskin.current.createPane('pane-map-TR_SEL');
        mapRefHeyelan.current.createPane('pane-map-TR_HEYELAN');
        mapRefDeprem.current.createPane('pane-map-TR_DEPREM')

        mapRefTaskin.current.getPane('pane-map-TR_SEL').style.zIndex = 310;
        mapRefHeyelan.current.getPane('pane-map-TR_HEYELAN').style.zIndex = 310;
        mapRefDeprem.current.getPane('pane-map-TR_DEPREM').style.zIndex = 310;

        window["rapor-taskin-tile"].addTo(mapRefTaskin.current);
        window["rapor-heyelan-tile"].addTo(mapRefHeyelan.current);
        window["rapor-deprem-tile"].addTo(mapRefDeprem.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickPrint = () => {
        window.print();
    }

    const onClickGoToMapPage = () => {
        navigate("/")
    }

    return (
        <>
            <div style={{ padding: "40px", maxWidth: "210mm", background: "#fff" }}>

                <div>
                    <Grid container>
                        <Grid item xs={9}>
                            <header id="header" className="print-header-view _lang_en"
                                style={{ marginBottom: "10px", marginTop: "10px" }}>
                                <img src="assets/images/atlasmutuel.png" alt='client-logo' style={{ height: "60px" }}></img>

                            </header>
                        </Grid>
                        <Grid item xs={3}>

                            <input type="button" id="yazdirbutton" value="YAZDIR"
                                className="btn btn-primary no-print" onClick={onClickPrint} style={{ float: "right" }}
                            />
                            <Button id="yazdirbutton" className='no-print' onClick={onClickGoToMapPage} style={{ float: "right" }}>
                                <ArrowBackIosNewIcon /> Geri
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <h5>
                                Heyelan, Taşkın ve Deprem Risk Raporu
                            </h5>
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: "25px" }}>
                            <Typography variant='body1' style={{ fontWeight: "bold" }}>Konum Bilgileri</Typography>
                            <Typography variant='body2'><b>Enlem: </b> {Number(props.store.selectedRisk[0]?.latitude).toFixed(5)}°</Typography>
                            <Typography variant='body2'><b>Boylam: </b> {Number(props.store.selectedRisk[0]?.longitude).toFixed(5)}°</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: "25px", textAlign: "right" }}>
                            <Typography variant='body2'><b>Tarih: </b>{dayjs().format("DD.MM.YYYY HH:MM")}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "35px" }}>
                            <Grid container spacing={1} style={{ border: "1px solid gray" }}>
                                <Grid item xs={4} style={{ paddingTop: "0", paddingLeft: "0", borderRight: "1px solid gray" }}>
                                    <Typography variant='body2' style={{ textAlign: "center" }}> <b>Harita 1.</b> Taşkın Risk Haritası</Typography>
                                    <div id="maptaskin" style={{ width: "100%", height: "250px", borderTop: "1px solid gray" }}>
                                    </div>
                                </Grid>
                                <Grid item xs={4} style={{ paddingTop: "0", paddingLeft: "0", borderRight: "1px solid gray"  }}>
                                    <Typography variant='body2' style={{ textAlign: "center" }}> <b>Harita 2.</b> Heyelan Risk Haritası</Typography>
                                    <div id="mapheyelan" style={{ width: "100%", height: "250px", borderTop: "1px solid gray" }}>
                                    </div>
                                </Grid>
                                <Grid item xs={4} style={{ paddingTop: "0", paddingLeft: "0"}}>
                                    <Typography variant='body2' style={{ textAlign: "center" }}> <b>Harita 3.</b> Deprem Risk Haritası</Typography>
                                    <div id="mapdeprem" style={{ width: "100%", height: "250px", borderTop: "1px solid gray" }}>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={1} style={{ border: "1px solid gray", marginTop: "25px" }}>
                                <Grid item xs={12} style={{ borderBottom: "1px solid gray", textAlign: "center" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold", marginBottom: "7px" }}>Risk Bilgileri</Typography>
                                </Grid>
                                <Grid item xs={4} justify="center" style={{ padding: "20px",  borderRight: "1px solid gray", textAlign: "center"  }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Taşkın Risk Skoru</Typography>
                                    <Avatar sx={{
                                        width: 75, height: 75,
                                        bgcolor: (props.store.selectedRisk[0]?.floodrisk === 5) ? "#ff0000" :
                                            (props.store.selectedRisk[0]?.floodrisk === 4) ? "#f73e3f" :
                                                (props.store.selectedRisk[0]?.floodrisk === 3) ? "#ff7f02" :
                                                    (props.store.selectedRisk[0]?.floodrisk === 2) ? "#feea00" :
                                                        (props.store.selectedRisk[0]?.floodrisk === 1) ? "#00e03c" : "",
                                    }} style={{marginLeft: "calc(50% - 33px)"}}>
                                        <Typography variant='h4' style={{background: "#000", padding: "3px", lineHeight: "25px"}}>{props.store.selectedRisk[0]?.floodrisk}</Typography>
                                    </Avatar>
                                </Grid>
                                <Grid item xs={4} justify="center" style={{  borderRight: "1px solid gray",padding: "20px" , textAlign: "center"}}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Heyelan Risk Skoru</Typography>

                                    <Avatar sx={{
                                        width: 75, height: 75,
                                        bgcolor: (props.store.selectedRisk[0]?.landsliderisk === 5) ? "#ff0000" :
                                        (props.store.selectedRisk[0]?.landsliderisk === 4) ? "#f73e3f" :
                                            (props.store.selectedRisk[0]?.landsliderisk === 3) ? "#ff7f02" :
                                                (props.store.selectedRisk[0]?.landsliderisk === 2) ? "#feea00" :
                                                    (props.store.selectedRisk[0]?.landsliderisk === 1) ? "#00e03c" : "",
                                    }} style={{marginLeft: "calc(50% - 33px)"}}>
                                        <Typography variant='h4' style={{background: "#000", padding: "3px", lineHeight: "25px"}}>{props.store.selectedRisk[0]?.landsliderisk}</Typography>
                                    </Avatar>
                                </Grid>
                                <Grid item xs={4} justify="center" style={{ padding: "20px", textAlign: "center"}}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Deprem Risk Skoru</Typography>

                                    <Avatar sx={{
                                        width: 75, height: 75,
                                        bgcolor: (props.store.selectedRisk[0]?.earthquakerisk === 5) ? "#ff0000" :
                                        (props.store.selectedRisk[0]?.earthquakerisk === 4) ? "#f73e3f" :
                                            (props.store.selectedRisk[0]?.earthquakerisk === 3) ? "#ff7f02" :
                                                (props.store.selectedRisk[0]?.earthquakerisk === 2) ? "#feea00" :
                                                    (props.store.selectedRisk[0]?.earthquakerisk === 1) ? "#00e03c" : "",
                                    }} style={{marginLeft: "calc(50% - 33px)"}}>
                                        <Typography variant='h4' style={{background: "#000", padding: "3px", lineHeight: "25px"}}>{props.store.selectedRisk[0]?.earthquakerisk}</Typography>
                                    </Avatar>
                                </Grid>
                                
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "25px" }}>
                            <Grid container spacing={1} style={{ border: "1px solid gray" }}>
                                <Grid item justify="center" xs={4} style={{ borderRight: "1px solid gray", padding: "10px" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Taşkın Harita Lejantı</Typography>
                                    <img src="assets/images/lejant.png" alt='layer-taskin-lejant' style={{ width: "100px" }}></img>

                                </Grid>
                                <Grid item justify="center" xs={4} style={{  borderRight: "1px solid gray", padding: "10px" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Heyelan Harita Lejantı</Typography>
                                    <img src="assets/images/lejant.png" alt='layer-heyelan-lejant' style={{ width: "100px" }}></img>
                                </Grid>
                                <Grid item justify="center" xs={4} style={{ padding: "10px" }}>
                                    <Typography variant='body2' style={{ fontWeight: "bold" }}>Deprem Harita Lejantı</Typography>
                                    <img src="assets/images/lejant.png" alt='layer-heyelan-lejant' style={{ width: "100px" }}></img>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' style={{ marginTop: "25px" }}>Bu rapor, {props.store.user[0].name + " "  + props.store.user[0].surname} kullanıcısı tarafından
                                üretilmiştir.</Typography>
                        </Grid>
                    </Grid>

                </div>
            </div>
        </>
    )
}))

export default RaporPage;


