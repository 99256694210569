import React, { useState } from 'react';
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom';

import L from 'leaflet';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import { Button, CardContent } from '@mui/material';
import { Card, Paper, Slide } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import WestTwoToneIcon from '@mui/icons-material/WestTwoTone';

import { RiskAPI } from '../../apis/RiskAPI';
import { Risk } from '../../apis/model/ingrit/Risk';
import { BasarsoftGeocoderAPI } from '../../apis/BasarsoftGeocoderAPI';

export const RiskAdresSorgulaMenu = inject("store")(observer((props) => {

    const navigate = useNavigate();

    const [geocoderResultDisplay, setGeocoderResultDisplay] = useState(false)
    const [geocode, setGeocode] = useState("")
    const [geocodeResultArray, setGeocodeResultArray] = useState([])

    /**
     * geocode text inputu için change event metodu
     * @param {*} event 
     */
    const onGeocodeTextInput = (event) => {
        setGeocode(event.target.value)
    }

    /**
     * geocode text inputuna girilen değere ggöree geocode isteği yapar
     */
    const onGeocodeReqClick = async () => {
        props.store.setLoadingBarStatus(false)
        let geocodeResult = await BasarsoftGeocoderAPI.geocode(props, encodeURI(geocode), false)
        console.log("geocodeResult: ", geocodeResult)
        if (geocodeResult.sonuc_kumesi.length > 0) {
                setGeocoderResultDisplay(true)
                setGeocodeResultArray(geocodeResult.sonuc_kumesi)
                //setGeocodeResultArray(geocodeResult.data.features)
        }
        
        props.store.setLoadingBarStatus(true)
    }

    /**
     * Geocode sonuç listesinin gösterildiği soonuç panelinin kapatılmasını sağlar
     */
    const onClickDeleteGeocoderResult = async () => {
        setGeocoderResultDisplay(false)
        setGeocode("")
        setGeocodeResultArray([])
        props.store.setSelectedRisk(Risk.create({}))
        props.store.setAttributeTableDisplay(false)

        if (props.queryGeojsonRef.current !== null) {
            props.mapRef.current.removeLayer(props.queryGeojsonRef.current)
        }
    }
    /**
     * Geocode sonuç listesinden seçilen bir sonucunun haritada gösterilmesini sağlar
     * @param {*} selectedResult  
     */
    const onSelectedGeocodeQueryResult = (selectedResult) => {
        if (props.queryGeojsonRef !== undefined) {

            if (props.queryGeojsonRef.current !== null) {
                props.mapRef.current.removeLayer(props.queryGeojsonRef.current);
            }

            props.queryGeojsonRef.current = L.geoJSON(
                {
                    "type": "Point",
                    "coordinates": [selectedResult.boylam, selectedResult.enlem]
                }).addTo(props.mapRef.current);
            props.mapRef.current.fitBounds(props.queryGeojsonRef.current.getBounds());
            props.mapRef.current.setZoom(props.mapRef.current.getZoom() - 7)

            let lat = selectedResult.boylam
            let lng = selectedResult.enlem

            RiskAPI.getRiskByLatLng(props, lat, lng, false).then(
                (riskApiResponse) => {
                    props.store.setLoadingBarStatus(false)
                    if (riskApiResponse.result !== null) {

                        let resultRisk = Risk.create({
                            latitude: riskApiResponse?.result?.lat,
                            longitude: riskApiResponse?.result?.lng,
                            floodrisk: riskApiResponse?.result?.sel,
                            landsliderisk: riskApiResponse?.result?.heyelan,
                            earthquakerisk: riskApiResponse?.result?.deprem,
                        })

                        if (resultRisk.landsliderisk > 5 || resultRisk.floodrisk > 5) {
                            props.store.setMessage("Sorguladığınız enlem boylam için risk bilgisi bulunamadı", true, "error")
                            props.store.setAttributeTableDisplay(false)
                        } else {
                            props.store.setSelectedRisk(resultRisk)
                            props.store.setAttributeTableDisplay(true)
                        }

                        if (props.queryGeojsonRef.current !== null) {
                            props.mapRef.current.removeLayer(props.queryGeojsonRef.current)
                        }

                        let geojson = {
                            "type": "Feature",
                            "properties": {
                            },
                            "geometry": {
                                "type": "Point",
                                "coordinates": [lat, lng]
                            }
                        };

                        props.queryGeojsonRef.current = L.geoJSON(geojson).addTo(props.mapRef.current);
                        props.mapRef.current.fitBounds(props.queryGeojsonRef.current.getBounds(), { maxZoom: 15 });
                        //props.mapRef.current.
                    }
                    props.store.setLoadingBarStatus(true)
                }
            ).catch(e => {
                console.log("e: ", e)
                if (e.response.status === 404) {
                    props.store.setMessage("Sorguladığınız enlem boylam için risk bilgisi bulunamadı", true, "error")
                    props.store.setLoadingBarStatus(true)
                } else if (e.response.status === 401) {
                    props.store.setUserLogout()
                    props.store.setMapAuthorizedOverlayServiceList([])
                    props.store.setOverlayServiceList([])
                    props.store.setLoadingBarStatus(true)
                    navigate("/ingrit/kullanici-girisi");
                }
            })
        }
    }

    const onClickCloseGeocoderMenu = () => {
        props.store.setMapGeocoderMenuDisplay(false)
    }

    return (
        <Slide direction="right" in={props.store.mapGeocoderMenuDisplay} mountOnEnter >
            <Paper className="mps-left-panel" style={{ borderRadius: "0 !important", width: "400px", height: "100vh" }} elevation={0}>
                <Card style={{ height: "100vh" }}>
                    <CardHeader subheader={<Typography variant="body1">
                        Adres Sorgulama Menüsü
                    </Typography>} action={
                        <IconButton aria-label="settings" onClick={onClickCloseGeocoderMenu}>
                            <WestTwoToneIcon />
                        </IconButton>
                    }></CardHeader>

                    <CardContent>
                        <FormControl variant='filled' style={{ width: "calc(100% - 65px)", marginBottom: "0px" }}>
                            <TextField label="Adres ara: " variant="outlined"
                                name="GEOCODE" value={geocode} onChange={onGeocodeTextInput}>
                            </TextField>
                        </FormControl>

                        <Button style={{ height: "56px", borderRadius: "0" }}
                            aria-label="geocode" color="primary" variant='contained'
                            onClick={onGeocodeReqClick}
                            edge="end">
                            <SearchIcon />
                        </Button>
                        <Button style={{ float: "right" }} size='small'
                            aria-label="temizle" color="mps"
                            onClick={onClickDeleteGeocoderResult}
                            edge="end">
                            Sonuçları Temizle
                        </Button>

                        <Grid container spacing={0} >
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{ fontWeight: "bold", marginTop: "5px", marginLeft: "10px" }}>
                                    Arama Sonuçları
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <List style={{ display: (geocoderResultDisplay) ? "block" : "none" }}>
                                    {(geocodeResultArray.length > 0) ? geocodeResultArray.map((item, idx) => (
                                        <ListItem key={idx} className="geocoder-result-item" 
                                            onClick={() => onSelectedGeocodeQueryResult(item)}>
                                            <ListItemText primary={item.posta_adresi} />
                                        </ListItem>
                                    )) : ""}
                                </List>
                            </Grid>
                        </Grid>
                    </CardContent>
            </Card>
            </Paper>
            </Slide>
            )

}))