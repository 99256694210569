import React, { useCallback, useRef, useState } from 'react'
import { observer, inject } from "mobx-react"

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PanToolIcon from '@mui/icons-material/PanTool';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import CloseIcon from '@mui/icons-material/Close';

import { Button, Card, CardContent, CardHeader, TextField, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import "proj4";
import "proj4leaflet";
import proj4 from 'proj4';
import L from 'leaflet';

import config from '../../config/client.json'

const epsg4326 = 'GEOGCS["WGS 84",DATUM["WGS_1984",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4326"]]';
const targetCrs = config.mapCoordinateSystem

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const MpsZoomBoxMenu = inject("store")(observer((props) => {

    let activeMapPositionIndex = useRef(0);

    const [goToCoordinatesMenuDisplay, setGoToCoordinatesMenuDisplay] = useState(false)

    const coordinateSystemList =
        [
            {
                id: 0,
                name: "Coğrafi Koordinat"
            }
        ];

    const [selectedCoordinateSystem, setSelectedCoordinateSystem] = useState(0)
    const [coordLatX, setCoordLatX] = useState(0)
    const [coordLngY, setCoordLngY] = useState(0)

    const onZoomInClick = useCallback(() => {
        props.mapRef.current.setZoom(props.mapRef.current.getZoom() + 1)
        props.leafletZoomBox.current.hide()
        // eslint-disable-next-line
    }, [props.mapRef.current])

    const onZoomOutClick = useCallback(() => {
        props.mapRef.current.setZoom(props.mapRef.current.getZoom() - 1)
        props.leafletZoomBox.current.hide()
        // eslint-disable-next-line
    }, [props.mapRef.current])

    const onZoomToExtent = useCallback(() => {
        //console.log(extentGeojson.current)
        props.mapRef.current.fitBounds(props.extentGeojson.current.getBounds());
        props.leafletZoomBox.current.hide()
        // eslint-disable-next-line
    }, [props.mapRef.current])

    const onClickBackMapView = useCallback(() => {
        // console.log("props.mapPositionHistory: ", props.mapPositionHistory)
        props.leafletZoomBox.current.hide()
        if (activeMapPositionIndex.current === 0) {
            activeMapPositionIndex.current = props.mapPositionHistory.current.length - 1;
        }

        if (activeMapPositionIndex.current > 0) {
            activeMapPositionIndex.current = activeMapPositionIndex.current - 1;
            if (activeMapPositionIndex.current !== 0) {
                let mapPosition = props.mapPositionHistory.current[activeMapPositionIndex.current]
                props.mapRef.current.setView(mapPosition.center, mapPosition.zoom)
            } else {
                activeMapPositionIndex.current = activeMapPositionIndex.current + 1
            }
        }
        // eslint-disable-next-line
    }, [props.mapRef])

    const onClickForwardMapView = useCallback(() => {
        props.leafletZoomBox.current.hide()
        //console.log("props.mapPositionHistory: ", props.mapPositionHistory)
        if (activeMapPositionIndex.current < props.mapPositionHistory.current.length - 1) {
            activeMapPositionIndex.current = activeMapPositionIndex.current + 1;
            let mapPosition = props.mapPositionHistory.current[activeMapPositionIndex.current]
            if (mapPosition !== undefined) {
                props.mapRef.current.setView(mapPosition.center, mapPosition.zoom)
            }
        }
        // eslint-disable-next-line
    }, [props.mapRef])

    const onClickActivePanning = useCallback(() => {
        props.leafletZoomBox.current.hide()
        document.getElementById("mps-container").style.cursor = 'grab'
        // props.store.setMapGetFeatureInfoButtonStatus(false)
        // eslint-disable-next-line
    }, [])

    const onClickLocationButton = () => {
        props.leafletZoomBox.current.hide()
        props.mapRef.current.locate({ setView: true, watch: true }) /* This will return map so you can do chaining */
            .on('locationfound', function (e) {
            })
            .on('locationerror', function (e) {
                props.store.setMessage("Konum erişimi reddildiği için haritada konumunuzu gösteremiyoruz.", true, "error")
            });
    }

    const onClickOpenGoToCoordinatesMenu = () => {
        setGoToCoordinatesMenuDisplay(!goToCoordinatesMenuDisplay)
    }

    const handleChangeCoordinateSystem = (event) => {
        setSelectedCoordinateSystem(event.target.value)
    }

    const onClickGotoCoords = () => {
        if (selectedCoordinateSystem === 0) {
            props.mapRef.current.setView([coordLngY, coordLatX], 15);
            let geojsonFeature = {
                "type": "Feature",
                "properties": {
                },
                "geometry": {
                    "type": "Point",
                    "coordinates": [Number(coordLngY), Number(coordLatX)]
                }
            };
            props.extentGeojson.current = L.geoJSON(geojsonFeature);
            props.extentGeojson.current.addTo(props.mapRef.current);

        } else {
            let tempCoords = proj4(targetCrs, epsg4326, [Number(coordLngY), Number(coordLatX)])
            props.mapRef.current.setView([tempCoords[1], tempCoords[0]], 15);

            let geojsonFeature = {
                "type": "Feature",
                "properties": {
                },
                "geometry": {
                    "type": "Point",
                    "coordinates": [tempCoords[1], tempCoords[0]]
                }
            };

            props.extentGeojson.current = L.geoJSON(geojsonFeature)
            props.extentGeojson.current.addTo(props.mapRef.current);

        }
    }

    return (
        <>
            <div className="mps-zoomtools-container zoombox-intro">
                <Tooltip title="Koordinata Git" placement="left" >
                    <IconButton aria-label="panning" className="mps-zoomtools-button"
                        style={(props.store.darkMode) ? { background: "#20142e" } : { background: "#fff" }}
                        onClick={onClickOpenGoToCoordinatesMenu} >
                        <FmdGoodIcon />
                    </IconButton>
                </Tooltip>

                <div>
                </div>

                <Tooltip title="Kaydır" placement="left" >
                    <IconButton aria-label="panning" className="mps-zoomtools-button"
                        style={(props.store.darkMode) ? { background: "#20142e" } : { background: "#fff" }}
                        onClick={onClickActivePanning} >
                        <PanToolIcon />
                    </IconButton>
                </Tooltip>

                <div>
                </div>

                <Tooltip title="Bir önceki ekrana git" placement="left">
                    <IconButton aria-label="go-back" className={"mps-zoomtools-button"}
                        style={(props.store.darkMode) ? { background: "#20142e" } : { background: "#fff" }}
                        onClick={onClickBackMapView}>
                        <ArrowBackIcon />
                    </IconButton>
                </Tooltip>

                <div>
                </div>

                <Tooltip title="Bir sonraki ekrana git" placement="left">
                    <IconButton aria-label="go-forward" className={"mps-zoomtools-button"} style={(props.store.darkMode) ? { background: "#20142e" } : { background: "#fff" }} onClick={onClickForwardMapView}>
                        <ArrowForwardIcon />
                    </IconButton>
                </Tooltip>

                <div>
                </div>

                <Tooltip title="Dış sınıra zoom yap" placement="left">
                    <IconButton aria-label="zoom-to-extent" className={"mps-zoomtools-button"} style={(props.store.darkMode) ? { background: "#20142e" } : { background: "#fff" }} onClick={onZoomToExtent}>
                        <HomeIcon />
                    </IconButton>
                </Tooltip>

                <div className="map-controls-zoombox">
                    <Tooltip title="Yakınlaş" placement="left">
                        <IconButton aria-label="zoom-in" className={"mps-zoomtools-button"} style={(props.store.darkMode) ? { background: "#20142e" } : { background: "#fff" }} onClick={onZoomInClick}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Uzaklaş" placement="left">
                        <IconButton aria-label="zoom-out" className={"mps-zoomtools-button"} style={(props.store.darkMode) ? { background: "#20142e" } : { background: "#fff" }} onClick={onZoomOutClick}>
                            <RemoveIcon />
                        </IconButton>
                    </Tooltip>
                </div>

                <Tooltip title="Konumu bul" placement="left">
                    <IconButton aria-label="locate-user" className={"mps-zoomtools-button"} style={(props.store.darkMode) ? { background: "#20142e" } : { background: "#fff" }}
                        onClick={onClickLocationButton}>
                        <MyLocationIcon />
                    </IconButton>
                </Tooltip>
            </div>

            <Card className="mps-goto-coordinates-menu" style={{ display: (goToCoordinatesMenuDisplay) ? "block" : "none" }}>
                <CardHeader subheader={<Typography variant='body2'>
                    Koordinata Git Menüsü
                </Typography>} style={{paddingLeft: 0}}
                    action={
                        <IconButton aria-label="settings" onClick={() => setGoToCoordinatesMenuDisplay(false)}>
                            <CloseIcon />
                        </IconButton>
                    }>
                </CardHeader>
                <CardContent style={{ textAlign: "center", padding: "5px" }}>
                    <FormControl fullWidth variant='outlined' style={{ marginTop: "20px" }}>
                        <InputLabel id="selectilcelabel" style={{ marginBottom: "10px" }}>Koordinat Sistemi Seçiniz:</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select" required label="Koordinat Sistemi Seçiniz:"
                            value={selectedCoordinateSystem}
                            MenuProps={MenuProps} size="small" style={{ width: "100%" }}
                            onChange={handleChangeCoordinateSystem} >
                            <MenuItem key={-1} value={-1}>
                                <Typography variant='body2' style={{ textAlign: "left" }}>Katman Seçiniz</Typography>
                            </MenuItem>
                            {coordinateSystemList.map((coordSystem, idx) => (
                                <MenuItem key={idx} value={coordSystem.id}>
                                    <Typography variant='body2' style={{ textAlign: "left" }}>{coordSystem.name}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant='outlined' style={{ marginTop: "20px" }}>
                        <TextField value={coordLatX} label={(selectedCoordinateSystem === 0) ? "Enlem" : "X Koordinatı"}
                            onChange={(e) => setCoordLatX(e.target.value)} size="small">
                        </TextField>
                    </FormControl>

                    <FormControl fullWidth variant='outlined' style={{ marginTop: "20px" }}>
                        <TextField value={coordLngY} label={(selectedCoordinateSystem === 0) ? "Boylam" : "Y Koordinatı"}
                            onChange={(e) => setCoordLngY(e.target.value)} size="small">
                        </TextField>
                    </FormControl>

                    <Button style={{ float: "right", marginTop: "15px", marginBottom: "10px" }} color='primary'
                        variant='contained' onClick={onClickGotoCoords}>
                        <FmdGoodIcon /> Koordinata Git
                    </Button>

                </CardContent>
            </Card>

        </>
    )
}))