import React, { useEffect } from 'react';
import { observer, inject } from "mobx-react";
import { useNavigate } from 'react-router-dom';

import MapissoAlert from '../components/common/MapissoAlert';
import { OverlayService } from '../apis/model/arcgisserver/OverlayService';
import { MpsMapViewer } from '../mapviewer/MpsMapViewer'

const HaritaPage = inject("store")(observer((props) => {

    const navigate = useNavigate();

    const mapWidth = "calc(100% - 50px)"
    const mapHeight = "100vh"

    /**
     * Feature seçildiğinde seçilen feature için layer bilgisinin alınmasını sağlar
    */
    useEffect(() => {
        //console.log("props.selectedFeature: ", props.selectedFeature)
        if (props.store.isLogin) {
            props.store.setLoadingBarStatus(false)
            let overlayServiceList = []

            let heyelanRiskOverlayService = OverlayService.create({
                id: 1,
                name: "Heyelan Risk Katmanı",
                url: "https://tile.reazy.co/v1/heyelan/{z}/{x}/{y}.png?access_token=" + props.store.apikey,
                key: "Heyelan",
                active: 0,
                opacity: 100,
                queryable: 1,
                type: 0,
                zIndex: 5000,
                legends: "/assets/images/lejant.png"
            });

            let taskinRiskOverlayService = OverlayService.create({
                id: 2,
                name: "Sel Risk Katmanı",
                url: "https://tile.reazy.co/v1/sel/{z}/{x}/{y}.png?access_token=" + props.store.apikey,
                key: "Sel",
                active: 0,
                opacity: 100,
                queryable: 1,
                type: 0,
                zIndex: 5000,
                legends: "/assets/images/lejant.png"
            });

            let depremRiskOverlayService = OverlayService.create({
                id: 3,
                name: "Deprem Risk Katmanı",
                url: "https://tile.reazy.co/v1/deprem/{z}/{x}/{y}.png?access_token=" + props.store.apikey,
                key: "Deprem",
                active: 0,
                opacity: 100,
                queryable: 1,
                type: 0,
                zIndex: 5000,
                legends: "/assets/images/lejant.png"
            });

            overlayServiceList.push(heyelanRiskOverlayService)
            overlayServiceList.push(taskinRiskOverlayService)
            overlayServiceList.push(depremRiskOverlayService)

            // props.store.setOverlayServiceList(overlayServiceList)
            props.store.setMapAuthorizedOverlayServiceList(overlayServiceList)
            props.store.setLoadingBarStatus(true)
        }else{
            navigate("/kullanici-girisi")
        }
        // eslint-disable-next-line
    }, []);

    

    return (
        <>
            <MpsMapViewer id="mps-container" mapData={null}
                baseLayer={props.store.baseLayerList[3]}
                width={mapWidth} height={mapHeight}
                showPaginationMenu={true} />
            <MapissoAlert />
        </>
    )
}))

export default HaritaPage;