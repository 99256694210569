import React, { useState } from 'react';
import { observer, inject } from "mobx-react"

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import { Button, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Card, Paper, Slide } from '@mui/material';

import WestTwoToneIcon from '@mui/icons-material/WestTwoTone';
import KeyIcon from '@mui/icons-material/Key';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import Draggable from 'react-draggable';
import { User } from '../../apis/model/mapisso/User';
import { UserAPI } from '../../apis/UserAPI';
import { toJS } from 'mobx';

export const MapUserMenu = inject("store")(observer((props) => {

    const [selectedUser, setSelectedUser] = useState(User.create({}))
    const [apiKey, setApiKey] = useState("")
    const [userMenuDisplay, setUserMenuDisplay] = useState(false)
    const [userDeleteMenuDisplay, setUserDeleteMenuDisplay] = useState(false)

    const [addUserMenuDisplay, setAddUserMenuDisplay] = useState(false)

    const [addUserName, setAddUserName] = useState("")
    const [addUserEmail, setAddUserEmail] = useState("")
    const [addUserPassword, setAddUserPassword] = useState("")
    const [addUserSurname, setAddUserSurname] = useState("")

    const onClickCloseUserMenu = () => {
        props.store.setMapUserMenuDisplay(false)
    }

    const onClickUpdateApiKey = async () => {
        let updatedUser = toJS(selectedUser);
        updatedUser.apikey = apiKey;
        delete updatedUser.email

        let userUpdateAPIResponse = await UserAPI.updateUser(props, selectedUser.id, updatedUser);
        if (userUpdateAPIResponse.status === 200) {
            props.store.setMessage("API anahatarı bilgisi başarılı bir şekilde güncellendi.", true, "success")
            let userAPIResponse = await UserAPI.getUserList(props, false)
            if (userAPIResponse.status === 200) {
                props.store.setUserList(userAPIResponse.result)
            }

        } else {
            props.store.setMessage("API anahtarı bilgisi güncellenirken bir hata oluştu.", true, "error")
        }
    }

    const onClickOpenApiKeyUpdatePanel = (inputUser) => {
        setSelectedUser(inputUser)
        setApiKey(inputUser.apikey)
        setUserMenuDisplay(true)
    }

    const onClickDeleteUserMenuOpen = (inputUser) => {
        setSelectedUser(inputUser)
        setUserDeleteMenuDisplay(true)
    }

    const onClickDeleteSelectedUser = async () => {
        props.store.setLoadingBarStatus(false)
        let userAPIResponse = await UserAPI.deleteUser(props, selectedUser.id);
        if (userAPIResponse.status === 200) {
            props.store.setMessage("Kullanıcı başarılı bir şekilde silindi.", true, "success")
            let userAPIResponse = await UserAPI.getUserList(props, false)
            console.log("userAPIResponse: ", userAPIResponse)
            if (userAPIResponse.status === 200) {
                props.store.setUserList(userAPIResponse.result)
            } else {
                props.store.setUserList([])
            }
        }else {
            props.store.setMessage("Kullanıcı silinirken bir hata oluştu.", true, "error")
        }

        setUserDeleteMenuDisplay(false)
        props.store.setLoadingBarStatus(true)
    }

    const onClickAddUser = async () => {

        props.store.setLoadingBarStatus(false)
        let addUser = {
            name: addUserName,
            surname: addUserSurname,
            email: addUserEmail,
            password: addUserPassword
        }
        if (addUserName !== "" && addUserSurname !== "" && addUserEmail !== "" && addUserPassword !== "") {
            let addUserResponse = await UserAPI.addUser(props, addUser, false);
            console.log("addUserResponse: ", addUserResponse)
            if (addUserResponse.status === 200) {
                props.store.setMessage("Kullanıcı başarılı bir şekilde eklendi.", true, "success")
                let userAPIResponse = await UserAPI.getUserList(props, false)
                if (userAPIResponse.status === 200) {
                    props.store.setUserList(userAPIResponse.result)
                }
                setAddUserMenuDisplay(false)
            }
        } else {
            props.store.setMessage("Lütfen tüm alanları doldurunuz.", true, "error")
        }
        props.store.setLoadingBarStatus(true)
    }

    return (
        <>
            <Slide direction="right" in={props.store.mapUserMenuDisplay} mountOnEnter >
                <Paper className="mps-left-panel" style={{ borderRadius: "0 !important", width: "45%", height: "100vh" }} elevation={0}>
                    <Card style={{ height: "100vh" }}>
                        <CardHeader subheader={<Typography variant="body1">
                            Kullanıcı İşlemleri Menüsü
                        </Typography>} action={
                            <IconButton aria-label="settings" onClick={onClickCloseUserMenu}>
                                <WestTwoToneIcon />
                            </IconButton>
                        }></CardHeader>
                        <CardContent>
                            <Button color='primary' variant='contained' style={{ marginBottom: "10px", marginLeft: "auto" }}
                                onClick={() => setAddUserMenuDisplay(true)}>
                                <EditIcon /> Kullanıcı Ekle
                            </Button>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="body2">SIRA NO</Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography variant="body2">ADI SOYADI</Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography variant="body2">ADMİN DURUMU</Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography variant="body2">API Key Durumu</Typography>
                                            </TableCell>

                                            <TableCell>
                                                <Typography variant="body2">İŞLEMLER</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.store.userList.map((user, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {idx + 1}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {user.name + " " + user.surname}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {user.admin ? "Evet" : "Hayır"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2">
                                                        {user.apikey === "" ? "Tanımlı Değil" : "Tanımlı"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell style={{ minWidth: "150px" }}>
                                                    <Tooltip title="API Anahtarı Tanımla">
                                                        <IconButton color='primary' onClick={() => onClickOpenApiKeyUpdatePanel(user)}>
                                                            <KeyIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Kullanıcı Sil">
                                                        <IconButton onClick={() => onClickDeleteUserMenuOpen(user)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>

                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </CardContent>
                    </Card>
                </Paper>
            </Slide>
            <Draggable>
                <Card className="mps-attribute-table-container" hidden={(userMenuDisplay) ? false : true} >
                    <CardHeader subheader={
                        <Typography variant="body2" style={{ fontWeight: "bold" }}>
                            Kullanıcı API Key Tanımlama Menüsü
                        </Typography>
                    } action={
                        <IconButton aria-label="settings" onClick={() => setUserMenuDisplay(false)}>
                            <CloseIcon />
                        </IconButton>
                    }></CardHeader>
                    <CardContent style={{ padding: "20px" }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography variant="body2" style={{ marginBottom: "15px" }}>
                                    Bu menüden kullanıcının risk sorgulaması yapabilmesi için gerekli API Key tanımlamasını
                                    gerçekleştirebilirsiniz.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField value={apiKey} onChange={(e) => setApiKey(e.target.value)}
                                    style={{ width: "100%" }} label="API Key Bilgisi" />
                            </Grid>
                        </Grid>

                    </CardContent>
                    <CardActions >
                        <Button variant="contained" color="primary" style={{ marginLeft: "auto" }} onClick={onClickUpdateApiKey}>
                            Api Key Bilgisi Tanımla
                        </Button>
                    </CardActions>
                </Card>
            </Draggable>

            <Dialog open={userDeleteMenuDisplay}
                onClose={() => setUserDeleteMenuDisplay(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {"Kullanıcı'yı silmek istediğinize emin misiniz?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Bu kullanıcı sistemden silineceği için seçtiğiniz kullanıcı sisteme giriş yapamayacaktır.
                        Eminseniz, Sil butonuna tıklayarak bu işlemi gerçekleştirebilirsiniz
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUserDeleteMenuDisplay(false)}>Vazgeç</Button>
                    <Button onClick={onClickDeleteSelectedUser} autoFocus variant='contained'>
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>

            <Card className="mps-attribute-table-container" hidden={(addUserMenuDisplay) ? false : true} >
                <CardHeader subheader={
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                        Kullanıcı Ekleme Menüsü
                    </Typography>
                } action={
                    <IconButton aria-label="settings" onClick={() => setAddUserMenuDisplay(false)}>
                        <CloseIcon />
                    </IconButton>
                }></CardHeader>
                <CardContent style={{ padding: "20px" }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Typography variant="body2" style={{ marginBottom: "15px" }}>
                                Bu menüden kullanıcı ekleme işlemi yapabilirsiniz.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={addUserName} onChange={(e) => setAddUserName(e.target.value)}
                                style={{ width: "100%" }} label="Kullanıcı Adı" />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "15px" }}>
                            <TextField value={addUserSurname} onChange={(e) => setAddUserSurname(e.target.value)}
                                style={{ width: "100%" }} label="Kullanıcı Soyadı" />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "15px" }}>
                            <TextField value={addUserEmail} onChange={(e) => setAddUserEmail(e.target.value)}
                                style={{ width: "100%" }} label="Kullanıcı E-posta Adresi" />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "15px" }}>
                            <TextField type='password' value={addUserPassword} onChange={(e) => setAddUserPassword(e.target.value)}
                                style={{ width: "100%" }} label="Kullanıcı Şifresi" />
                        </Grid>
                    </Grid>

                </CardContent>
                <CardActions >
                    <Button variant="contained" color="primary" style={{ marginLeft: "auto" }} onClick={onClickAddUser}>
                        Kullanıcı Ekle
                    </Button>
                </CardActions>
            </Card>
        </>
    )

}))