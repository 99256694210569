import { types } from "mobx-state-tree"

export const EditingLayers = types
    .model({
        layerName: types.optional(types.string,""),
        layerGeojson: types.optional(types.string, ""),
        layerId: types.optional(types.number, 0),
        layerType: types.optional(types.string, "")
    }).actions(self => ({

    }))

 