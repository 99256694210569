import { types } from "mobx-state-tree"

export const User = types
    .model({
        id: types.maybeNull(types.optional(types.string, "")),
        name: types.maybeNull(types.optional(types.string,"")),
        surname: types.maybeNull(types.optional(types.string, "")),
        email: types.maybeNull(types.optional(types.string, "")),
        apikey: types.maybeNull(types.optional(types.string, "")),
        token: types.maybeNull(types.optional(types.string, "")),
        admin: types.maybeNull(types.optional(types.boolean, false))
    }).actions(self => ({

    }))

 